import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 25px;
    border-radius: 5px;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Actions = styled.div`
    display: flex;
    height: 50px;
    align-items: flex-end;
    justify-content: flex-end;

    button{
        width: 100px;
        height: 30px;
        margin-left: 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #19B674;
        cursor: pointer;
        color: white;

        :hover{
            opacity: 0.8;
        }
        
    }
    button:nth-child(1){
        background-color: transparent !important;
        border: 2px solid #19B674;
        color: black;
        //font-weight: bold;
    }
`;

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;

export const FormCustom = styled.div`
    display: flex;
    //background-color: whitesmoke;
    flex-direction: column;
`;

export const BoxInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    margin-bottom: 10px;

    label{
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;
    }

`;

export const TextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #EFEFEF;
    padding-right: 10px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-size: 14px;
    text-align: justify ;
`;

export const ButtonCustom = styled.button`
    display: flex;
    background-color: #ffcc00;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #5e0a07;
    font-weight: 600;
    cursor: pointer;
    :hover{
        opacity: 0.7;
    }
`;