import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    background-color: #f1f1f1 ;
    height: 115px ;
    display: flex ;
    align-items: center;
    padding: 0px 20px ;
    animation: Skeleton 1s ease infinite alternate;
    border-radius: 10px ;
    margin-bottom: 20px ;

    @keyframes Skeleton{
    to{
        opacity: 0.5;
    }
}
`



export const BoxCode = styled.div`
    background-color: #dcdcdc ;
    display: flex ;
    width: 120px ;
    height: 40px;
    border-radius: 5px;
`

export const BoxInfor = styled.div`
    //background-color: green ;
    display: flex ;
    flex: 1;
    margin-left: 20px;
    flex-direction: column ;
    gap: 10px;

    div:nth-child(1){
        width: 100px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(2){
        width: 150px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(3){
        width: 250px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }
`

export const BoxActions = styled.div`
    //background-color: currentColor ;
    display: flex ;
    gap: 20px;

    div:nth-child(1){
        width: 30px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(2){
        width: 30px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(3){
        width: 30px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }
    
`