import { getTranslation } from "../../../../translations";
import { Container, DataInfor, } from "./styled";

const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

const TableInfor = () => {
    return (
        <Container>
            <DataInfor>
                <thead>
                    <tr>
                        <th></th>
                        <th style={{ width: "360px", textAlign: "left" }}>{translation.dashboard.tableInfor.produtoAvaliados}</th>
                        <th>{translation.dashboard.tableInfor.media}</th>
                        <th>{translation.dashboard.tableInfor.total}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>1º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>2º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>3º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>4º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>5º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>6º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>7º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>8º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>9º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                    <tr>
                        <th>10º</th>
                        <th style={{ textAlign: "left", paddingLeft: "10px" }}>{translation.dashboard.tableInfor.produto}</th>
                        <th>4.7</th>
                        <th>55</th>
                    </tr>
                </tbody>
            </DataInfor>
        </Container>
    )
}

export default TableInfor