import 'bootstrap/dist/css/bootstrap.min.css';
import { UseAvaliacaoPopUp } from "../../hooks/UseAvaliacaoPopUp";
import { Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';


const AvaliacaoPopUp = () => {
	const { handleCloseAvaliacaoModal, showAvaliacaoModal } = UseAvaliacaoPopUp();
	const { iniciaAvaliacao } = UseAvaliacaoPopUp();
	const [isAlreadyAvaliated, setIsAlreadyAvaliated] = useState(
		localStorage.getItem("avaliacaoModal") ? JSON.parse(localStorage.getItem("avaliacaoModal")) : ""
	);

	useEffect(() => {
		iniciaAvaliacao("nuvemshop", "", "#avaliacao_integracao_test");
	}, []);






	return (
		<>
			<Modal
				className="pr-0 modal--avaliacao-app-container sak--modal-sm"
				size="xl"
				show={showAvaliacaoModal && isAlreadyAvaliated == 0 ? true : false}
				onHide={handleCloseAvaliacaoModal}
				id="avaliacao_integracao_test"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{" "}
						<div className="d-flex align-items-center">
							<img style={{ marginRight: "10px" }} height={40} width="40" src="img/avaliacaoAssets/girrafe.svg" />
							<h5 className="ml-2 mb-0">
								{
									JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ?
										"Tener reseñas en tu tienda" :
										"Tenha reviews na sua loja"
								}
								
							</h5>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>
						<hr className="mt-2 mb-2" />
						<ul id="sak--popupchat"></ul>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default AvaliacaoPopUp;
