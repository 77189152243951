import styled from "styled-components";

export const Container = styled.div`
    background-color: #FBF9F6 ;
    min-width: ${props => props.open ? "350px" : "30px"};
    //min-height: ${props => props.open ? "520px" : "0px"};
    max-width: ${props => props.open ? "350px" : "30px"};
    max-height: ${props => props.open ? "520px" : "0px"};
    display: flex;
    position: fixed ;
    right: 0;
    flex-direction: column;
    transition: width 0.5s;
    //top: 0 ;
    //border: 1px solid red;
    top: calc(50% - 260px);
    
`

export const Title = styled.div`
    display: flex ;
    height: 30px ;
    background-color: #FFC400 ;
    align-items: center ;
    //justify-content: space-evenly ;

    span{
        width: 30px ;
        height: 30px;
        display: flex;
        justify-content: center ;
        align-items: center ;
        cursor: pointer;

        svg{
            color: #191C30;
        }
    }
    label{
        display: ${props => props.open ? "flex" : "none"}; 
        font-size: 14px ;
        flex: 1 ;
        justify-content: center ;
        color: #191C30;


    }

    div{
        display: ${props => props.open ? "flex" : "none"}; 
        font-size: 14px ;
        opacity: 0.7 ;
        justify-content: center ;
        align-items: center ;
        background-color: #D9A700 ;
        width: 20px ;
        height: 20px;
        margin-right: 5px ;
        border-radius: 2px ;

        svg{
            color: #191C30;
        }
    }
`

export const Body = styled.div`
    //background-color: blue ;
    display: ${props => props.open ? "flex" : "none"};
    transition: width 1s;
    flex-direction: column ;
    padding: 16px ;
    overflow: scroll ;
   
`

export const Menu = styled.div`
    //background-color: white ;
    display: flex ;
    gap: 10px;
    margin-bottom: 10px ;

`

export const ItemMenu = styled.div`
    //max-width: 144px ;
    height: 37px ;
    display: flex ;
    //background-color: red ;
    align-items: center ;
    //justify-content: space-between ;
    padding: 20px ;
    flex: ${props => props.menuActive ? "1" : "initial"} ;
    cursor: pointer;
    transition: flex 0.5s;

    label{
        display: ${props => props.menuActive ? "flex" : "none"} ;
        font-size: 14px ;
        margin-left: 5px ;
        cursor: pointer;
        transition: 0.5s;
        color: ${props => props.menuActive ? "#191C30" : "gray"} ;
    }

    svg{
        color: ${props => props.menuActive ? "#191C30" : "gray"} ;
    }
`

export const BoxOptions = styled.div`
    //background-color: gray ;
    display: flex ;
    flex-direction: column ;
    gap: 24px;
`

export const BoxOptionsColors = styled.div`
    //background-color: gray ;
    display: flex ;
    flex-direction: column ;
    gap: 24px;
`

export const Option = styled.div`
    //background-color: red ;
    display: flex ;
    flex-direction: column ;

    label{
        font-size: 14px ;
        color: #353535 ;
        font-weight: 400 ;
    }
`

export const SelectCustom = styled.select`
    display: flex;
    background-color: #F5F3F0;
    height: 34px;
    width: 100% !important;
    font-weight: 600;
    color: #353535;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px ;

    @media(min-width: 480px) {
        width: 280px;
    }
`

export const InputCustomItem = styled.div`

    display: flex;
    flex-direction: column;
      
`;

export const ButtonCustom = styled.div`
    display: flex;
    background-color:  ${props => props.type == "save" ? "#19B674" : "#FBF9F6"};
    color: ${props => props.type == "save" ? "#FFFFFF" : "#353535"};
    height: 31px ;
    border-radius: 4px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border: ${props => props.type == "save" ? "none" : "1px solid #353535"};
    font-size: 10px ;
    cursor: pointer;

    label{
        cursor: pointer;
    }
`;


export const BoxActions = styled.div`
    //background-color: yellow ;
    display: flex ;
    flex-direction: column ;
    gap: 10px;
`;

export const ItemColor = styled.div`

    //background-color: red;
    display: flex;
    /* height: 40px;
    width: 40px;
    border-radius: 5px 0px 0px 5px;
    justify-content: center;
    align-items: center;
    border-width: 1px 0px 1px 1px;
    border-color: #EFF2F5;
    border-style: solid; */


    label{
        display: flex ;
        margin-left: 10px ;
        font-size: 12px ;
    }


    input[type=color] {
        width: 20px;
        height: 20px; 
        border-radius: 5px;
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        border: none;
        cursor: pointer;
        font-size: 11px ;   
        border: 1px solid #f1f1f144;
    }

    input[type=color]::-webkit-color-swatch {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

    input[type=color]::-webkit-color-swatch-wrapper {
        border: none;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        font-size: 11px ;
    }

`;

export const BoxItensColors = styled.div`
    display: flex ;
    //background-color: purple ;
    flex-direction: column ;
    gap: 10px;
    margin-top: 10px;
`


export const InputFileCustom = styled.div`
    display: flex;
    background-color: #f5f8fa;
    height: 40px;
    font-weight: 00;
    color: #353535;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 5px;
    align-items: center;
    justify-content: space-between;
    

    label{
        background-color: #ffcc00;
        display: flex;
        width: 50px !important;
        outline: none;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 5px 5px 5px 5px;
        font-size: 12px;
        //color: #5e0a07;
        font-weight: 600;
        color: #353535;
        padding: 0px  ;
    }

    p{
        display: flex;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        font-size: 8px;
    }

    @media(min-width: 480px) {
        width: 450px;
    }
`;

export const BoxLoadingCustom = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    //background-color: violet;
    div{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;


export const InputFileCustomTeste = styled.div`
    background-color: #F5F3F0;
    display: flex ;
    height: 34px;
    width: 100% !important;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 0px;
    position: relative ;
    align-items: center ;

    input{
        display: flex;
        background-color: transparent ;
        outline: none ;
        border: none ;
        padding-right: 20px !important;
        width: 100% ;
        font-size: 12px ;
        font-weight: 600;
        color: #353535;
        padding-left: 10px ;
    }

    label{
        display: flex ;
        position: absolute ;
        right: 10px;

        svg{
            cursor: pointer;
        }

    }
`

export const InputTextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #F5F3F0;
    border-radius: 5px;
    padding: 10px;
    resize: none;
 
    font-size: 12px ;
    font-weight: 400;
    color: #353535;
`

export const InputTextCustom = styled.div`
    display: flex ;
    position: relative;
    flex-direction: column ;
    justify-content: center ;

    input{
        display: flex;
        flex: 1;
        outline: none;
        border: none;
        background-color: #F5F3F0;
        border-radius: 5px;
        padding: 0px 60px 0px 10px;
        min-height: 34px;
        font-size: 12px ;
        color: #353535;
        font-weight: 600;
    }

    label{
        display: flex ;
        position: absolute;
        right: 5px;
        background-color: #FFCC00;
        border-radius: 5px;
        //padding: 0px 5px;
        color: #000000 ;
        font-size:11px ;
        width: 50px ;
        height: 24px;
        justify-content: center ;
        align-items: center ;
    }
    
`

export const CounterItemsCircle = styled.div`
    display: flex;
    position: absolute ;
    width: 20px;
    height: 20px ;
    align-items: center ;
    justify-content: center ;
    //background-color: red ;
    border-radius: 50% ;
    right: 10px ;
    bottom: 10px;
    //border: 1px solid ${props => props.colorBorder};
    //background-color: #F5F3F0;
    background: conic-gradient(#F13F6C 0% ${props => props.porc}, #ffffff 0% 100%);

    label{
        display: flex ;
        font-size: 8px !important;
        height: calc(100% - 2px) ;
        width: 100%;
        background-color: white;
        justify-content: center ;
        align-items: center;
        border-radius: 50%;
        margin: 1px;
        
    }
`
