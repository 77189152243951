import { Container, Content } from './styled'
import { IoIosClose } from "react-icons/io";
import { useState } from 'react';
import AlertCloseBossImg from "../../assets/imgs/gif-boss.gif";

const AlertCloseBoss = (props) => {

    const [visible, setVisible] = useState(props.visible)

    return (
        <Container borderleft={props.borderleft} close={props.close} visible={visible} background={props.background} margin={props.margin} padding={props.padding ? props.padding : "16px"}>
            <span className="svg-icon svg-icon-primary svg-icon-2x">
                <img width={32} height={32} src={AlertCloseBossImg} />
            </span>
            <Content>
                {props.children}
            </Content>
            <IoIosClose onClick={() => { setVisible(false) }} style={{ display: props.close ? "flex" : "none" }} size={25} color={"#99774F"} />
        </Container >
    );
}

export default AlertCloseBoss