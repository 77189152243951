import styled from "styled-components";


export const Container = styled.div`
    //background-color: yellow ;
    display: flex ;
    width: 100% ;
    flex-direction: column ;
    padding: 0px 20px;
    gap: 10px;
`

export const Title = styled.label`
    //background-color: blueviolet ;
    margin-bottom: 0px ;
    display: flex ;
    align-items: center ;

    label{
        display: flex ;
        flex: 1 ;
        //background-color: green ;
        justify-content: left ;
        font-size: 12px;
        color: #000 ;
        margin-left: 5px;
        cursor: pointer;
        
    }

    span{
        //background-color: red;
        cursor: pointer;
    }
`

export const Carrossel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    span{
        display: flex;
        //background-color: red;
        align-items: center;
        justify-content: space-between;

        img{
            margin-right: 10px;
        }
        
    }

`
export const Favoritas = styled.div`
     display: flex;
    flex-direction: column;
    gap: 10px;

    span{
        display: flex;
        //background-color: red;
        align-items: center;
        
        img{
            margin-right: 10px;
        }
        
    }

    div{
        display: flex;
        justify-content: center;
        div{
            display: flex;
            width: 85px;
            background-color: #1F85D7;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 34px;
            color:#fff;
            cursor: pointer;
            padding: 0px 10px;

            label{
                cursor: pointer;
                font-size: 11px;
                flex: 1;
            }
        }
    }
`

export const ItemActive = styled.div`
    display: flex;
    width: 30px;
    height: 20px;
    background-color: ${props => props.active ? "#00803B" : "gray"};
    align-items: center;
    padding: 0px 5px;
    border-radius: 5px;
    justify-content: ${props => props.active ? "right" : "left"};
    cursor: pointer;

    span{
        display: flex;
        height: 10px;
        width: 10px;
        background-color: white;
        border-radius: 2px;
    }
`

export const BoxOptions = styled.div`
    //background-color: red ;
    flex-direction: column ;
    display: flex ;
    gap: 10px;
    border: 1px solid #D3DAE3;
    padding: 10px ;
    border-radius: 4px;

    label{
        font-size: 10px ;
    }
`

export const ButtonHelp = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;
    background-color: #121212 ;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const ButtonSave = styled.div`
    background-color: #1F85D7; //#00803B ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: #fff ;
    cursor: pointer;
    flex: 1;
`

export const ButtonReset = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    border: 1px solid #D3DAE3;
    cursor: pointer;
    flex: 1;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #000 ;
    }

    img{
        width: 14px ;
        height: 16px ;
    }

`

export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`

export const SelectCustom = styled.select`
    display: flex;
    background-color: #F5F3F0;
    height: 34px;
    width: 100% !important;
    font-weight: 600;
    color: #353535;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px;
    cursor: pointer;

    @media(min-width: 480px) {
        width: 280px;
    }
`

export const ItemSelect = styled.div`
    //background-color:  yellow;
    display: flex ;
    flex-direction: column ;

    label{
        font-size: 10px ;
    }
`