import styled from 'styled-components'


export const Container = styled.div`
    animation: Skeleton 1s ease infinite alternate;
    background-color: #f1f1f1;
    border-radius: 5px ;
    display: flex ;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    margin-bottom: 30px ;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const Title = styled.div`
    background-color: #dcdcdc ;
    height: 80px ;
    border-radius: 5px ;
`

export const Description = styled.div`
    background-color: #dcdcdc ;
    height: 30px ;
    border-radius: 5px ;
`

export const Button = styled.div`
    background-color: #dcdcdc ;
    height: 50px ;
    width: 175px;
    border-radius: 5px ;
`

