
import { createContext, useContext, useState, useEffect } from 'react';

const BugerMenuContext = createContext({});

export const BurgerMenuProvider = ({ children }) => {
  const [burgerMenu, setBurgerMenu] = useState(window.innerWidth <= 768);

  return (
    <BugerMenuContext.Provider value={
      {
        burgerMenu,
        setBurgerMenu,
      }
    }>
      {children}
    </BugerMenuContext.Provider>
  );
}

export const useBurgerMenu = () => {
  const context = useContext(BugerMenuContext);

  return context;
}