import styled from "styled-components";

export const Container = styled.div`
    display: flex ;
    width: 400px;
    flex-direction: column ;
    //padding: 20px ;
    border-radius: 5px ;
`

export const Title = styled.div`
    display: flex ;
    height: 50px;
    //width: 100% ;
    background-color: #009879 ;
    justify-content: center ;
    align-items: center ;

    label{
        font-size: 18px ;
        font-weight: bold ;
        color: white;
    }
`

export const ButtomCustom = styled.div`
    display: flex ;
    background-color: ${props => props.backgroundColor} ;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    cursor: pointer;

    label{
        cursor: pointer;
        font-size: 12px ;
    }
`

export const Form = styled.div`
    display: flex ;
    //background-color: yellow ;
    padding: 20px 20px 0px 20px;
    flex-direction: column ;
    gap:10px;

    span{
        display: flex ;
        flex-direction: column ;

        label{
            font-size: 9px ;
        }

        input{
            height: 30px;
            font-size: 11px ;
            padding: 0px 10px ;
        }
    }

   
`

export const InputCustom = styled.div`
    display: flex ;
    width: 100% ;
    gap: 20px;

    input{
        display: flex;
        flex:1 ;
        background-color: #f1f1f1 ;
        outline: none ;
        border: none ;
        height: 40px ;
        padding: 0px 5px ;
        text-align: center ;
        min-width: 50px ;
        border-radius: 5px;
        color: gray;
        font-size: 14px ;
    }


    select{
        display: flex ;
        height: 40px ;
        min-width: 100px !important;
        justify-content: center ;
        padding-left: 5px ;
        background-color: #f1f1f1 ;
        border: none;
        cursor: pointer;
        text-align: center ;
        border-radius: 5px;
        color: gray ;
        font-size: 14px ;
    }
`

export const Actions = styled.div`
    display: flex ;
    justify-content: flex-end ;
    align-items: center ;
    gap: 20px;
    padding: 20px;
`