export const ThemeDefault = {
    colors: {
        layout: {
            primary: "#FFFFFF",
            secondary: "#FBF9F6",
        },
        font: {
            // default: "#5e627",
            // default: "#5e5268",
            default: "#5e6278",
            active: "#20d489"
        },
        buttons: {
            primary: "#FFFFFF",
            secondary: "#FBF9F6",
            border: "#d3d3d3"
        }
    },
}