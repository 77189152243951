
const INITIAL_STATE = {isIntegrationSteps: false}

const ControlIntegrationSteps = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'STEPS_CONTROL_INTEGRATION':
            return state = action.payload
        default:
            return state;
    }
}

export default ControlIntegrationSteps