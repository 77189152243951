import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 25px;
    border-radius: 5px;
    overflow: auto;
    //height: 600px;
  
    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;

export const Actions = styled.div` 
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;

    button{
        width: 100px;
        height: 30px;
        margin-left: 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #19B674;
        cursor: pointer;
        color: white;

        :hover{
            opacity: 0.8;
        }
        
    }
    button:nth-child(1){
        background-color: transparent !important;
        border: 2px solid #19B674;
        color: black;
        //font-weight: bold;
    }
`;

//tabela
export const TableCustom = styled.table`
    background-color: yellow;
    width: 150px;
    border: 8px solid #d3d3d344;
    border-radius: 10px;
    margin: auto auto;
    display: flex;
    flex-direction: column;

    tr{
        background-color: tomato;
        width: 100px;

        td{
            width: 100px;
            img{
                max-width: 250px;
            }
        }
    }

`
export const TrImageCustom = styled.table`
    background-color: purple;
    
    td{
        img{
            width: 200px;
        }
    }
`
export const Body = styled.div`
   
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-top: 10px;

`

export const BoxImage = styled.div`
   
   
   display: flex;
   justify-content: center;
   img{
       max-width: 300px;
       margin: 15px 0px;
       border-radius: 5px;
   }
`

export const BoxContent = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label{
        text-align: justify;
        margin: 0px 0px 10px 0px;
        max-width: 300px;
        pre {
            white-space: pre-wrap;       /* Since CSS 2.1 */
            white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
            white-space: -pre-wrap;      /* Opera 4-6 */
            white-space: -o-pre-wrap;    /* Opera 7 */
            word-wrap: break-word;       /* Internet Explorer 5.5+ */
        }
    }

    button{
        min-width: 100px;
        border-radius: 5px;
        height: 35px;
        background-color: black;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0px 7.5px;
    }
`
export const Item = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
`