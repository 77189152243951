import { BoxBarApps } from "../Header/styled";
import { Container } from "./styled";

const Body = (props) => {
    return (
        <>
            {/* <BoxBarApps className="container__script_top_menu_lily"></BoxBarApps> */}
            <Container>

                {props.children}
            </Container>
        </>

    )
}

export default Body
