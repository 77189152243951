import React, { useState } from 'react'
import { Body, BoxActions, BoxItensColors, BoxOptions, BoxOptionsColors, ButtonCustom, Container, CounterItemsCircle, InputTextAreaCustom, ItemColor, ItemMenu, Menu, Option, SelectCustom, Title } from './styled'
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoColorPaletteOutline } from "react-icons/io5";
import { VscGear } from "react-icons/vsc";
import { toast } from 'react-toastify';
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineReload } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTranslation } from '../../../../translations';

const BoxConfigTema = () => {

    const dispatch = useDispatch()

    
    //teste

    return ( null
        // <Container open={isOpenBox}>
        //     <Title open={isOpenBox}>
        //         <span>
        //             {isOpenBox ?
        //                 <IoIosArrowForward onClick={() => { setopenBox(false) }} color={"#191C30"} size={15} /> :
        //                 <IoIosArrowBack onClick={() => { setopenBox(true) }} color={"#191C30"} size={15} />}
        //         </span>
        //         <label>Editar</label>
        //         <div>
        //             <AiOutlineDesktop onClick={() => { setopenBox(true) }} color={"#ffffff"} size={12} />
        //         </div>
        //         {/* <div>
        //             <AiOutlineMobile onClick={() => { setopenBox(true) }} color={"#191C30"} size={12} />
        //         </div> */}
        //     </Title>
        //     <Body open={isOpenBox}>
        //         <Menu>
        //             <ItemMenu onClick={() => { setMenuActive("color") }} menuActive={menuActive == "color" ? true : false}>
        //                 <IoColorPaletteOutline onClick={() => { setopenBox(true) }} size={16} />
        //                 <label>Cores</label>
        //             </ItemMenu>
        //             <ItemMenu onClick={() => { setMenuActive("config") }} menuActive={menuActive == "config" ? true : false}>
        //                 <VscGear onClick={() => { setopenBox(true) }} size={16} />
        //                 <label>Ajustes</label>
        //             </ItemMenu>

        //         </Menu>


        //         {          //teste
        //             menuActive == "color" ?
        //                 <BoxOptionsColors>

        //                     <Option>
        //                         <label>Combinação de cores</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { setTheme(event.target.value) }}                        >
        //                             <option value="light" selected={theme == "light" ? true : false}>Light</option>
        //                             <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
        //                             <option disabled value="custom" selected={theme == "custom" ? true : false}>Personalizado</option></SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Cores do tema</label>
        //                         <BoxItensColors>
        //                             <ItemColor>
        //                                 <input type={"color"} value={primaryColor} onChange={(e) => setPrimaryColor(e.target.value)} />
        //                                 <label>Cor principal</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={backgroundColorPrimary} onChange={(e) => setBackgroundColorPrimary(e.target.value)} />
        //                                 <label>Cor de fundo principal</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={backgroundColorSecondary} onChange={(e) => setBackgroundColorSecondary(e.target.value)} />
        //                                 <label>Cor de fundo auxiliar</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={starColorPrimary} onChange={(e) => setstarColorPrimary(e.target.value)} />
        //                                 <label>Cor de estrelas ativas</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={starColorSecondary} onChange={(e) => setStarColorSecondary(e.target.value)} />
        //                                 <label>Cor de estrelas inativas</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={fontColorPrimary} onChange={(e) => setFontColorPrimary(e.target.value)} />
        //                                 <label>Cor principal dos textos</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={fontColorSecondary} onChange={(e) => setFontColorSecondary(e.target.value)} />
        //                                 <label>Cor auxiliar dos textos</label>
        //                             </ItemColor>
        //                         </BoxItensColors>
        //                     </Option>

        //                     <BoxActions>
        //                         <ButtonCustom onClick={() => saveTheme()} type={"save"}>
        //                             <label>Salvar</label>
        //                             <FaCheck color={"#FFFFFF"} size={12} />
        //                         </ButtonCustom>
        //                         <ButtonCustom onClick={() => { getDefaultTheme() }} type={"reset"}>
        //                             <label>Restaurar</label>
        //                             <AiOutlineReload color={"#353535"} size={12} />
        //                         </ButtonCustom>
        //                     </BoxActions>

        //                 </BoxOptionsColors> :

        //                 <BoxOptions>
        //                     <Option>
        //                         <label>Modelo Avaliações</label>
        //                         <SelectCustom name="select_layout_reviews" onChange={(event) => { handleLayout(event.target.value) }}                        >
        //                             <option value="lily" selected={layout == "lily" ? true : false}>Lily</option>
        //                             <option value="lily2" selected={layout == "lily2" ? true : false}>Lily 2</option>
        //                             <option value="lily3" selected={layout == "lily3" ? true : false}>Lily 3</option>
        //                             <option value="dropi" selected={layout == "dropi" ? true : false} >Dropi</option>
        //                             <option value="dropi2" selected={layout == "dropi2" ? true : false} >Dropi 2</option>
        //                             <option value="dropi3" selected={layout == "dropi3" ? true : false} >Dropi 3</option>
        //                             <option value="tiktok" selected={layout == "tiktok" ? true : false} >Tik Tok</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Modelo Perguntas</label>
        //                         <SelectCustom name="select_layout_questions" onChange={(event) => { handleLayoutQuentions(event.target.value) }}                        >
        //                             <option value="modelo1" selected={layoutQuestion == "modelo1" ? true : false}>Modelo 1</option>
        //                             <option value="modelo2" selected={layoutQuestion == "modelo2" ? true : false}>Modelo 2</option>
        //                             <option value="modelo3" selected={layoutQuestion == "modelo3" ? true : false}>Modelo 3</option>
        //                             <option value="modelo4" selected={layoutQuestion == "modelo4" ? true : false}>Modelo 4</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Tela avaliar</label>
        //                         <SelectCustom name="select_layout_form" onChange={(event) => { setLayoutForm(event.target.value) }}                        >
        //                             <option value="modelo1" selected={layoutForm == "modelo1" ? true : false}>Padrão</option>
        //                             <option value="modelo2" selected={layoutForm == "modelo2" ? true : false}>Modelo 2</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Tela perguntar</label>
        //                         <SelectCustom name="select_layout_form" onChange={(event) => { setLayoutFormAsk(event.target.value) }}                        >
        //                             <option value="modelo1" selected={layoutFormAsk == "modelo1" ? true : false}>Padrão</option>
        //                             <option value="modelo2" selected={layoutFormAsk == "modelo2" ? true : false}>Modelo 2</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Fonte</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { setfont(event.target.value) }}                        >
        //                             <option value="Poppins" selected={font == "Poppins" ? true : false}>Poppins</option>
        //                             <option value="Courier" selected={font == "Courier" ? true : false} >Courier</option>
        //                             <option value="Times" selected={font == "Times" ? true : false} >Times</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Página do produto</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { visibleMenu(event.target.value) }}                        >
        //                             <option value="0" selected={visible_button_ask == 1 && visible_button_review == 1 ? true : false}>Avaliações e Perguntas</option>
        //                             <option value="1" selected={visible_button_ask == 0 && visible_button_review == 1 ? true : false} >Somente Avaliações</option>
        //                             <option value="2" selected={visible_button_ask == 1 && visible_button_review == 0 ? true : false} >Somente Perguntas</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Sem avaliações</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { set_no_review_star(event.target.value) }}                        >
        //                             <option value="0" selected={no_review_star == 0 ? true : false}>Mostrar estrelas</option>
        //                             <option value="1" selected={no_review_star == 1 ? true : false} >Não mostrar estrelas</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Exibir datas</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { set_visible_date_review(event.target.value) }}                        >
        //                             <option value="1" selected={visible_date_review == 1 ? true : false}>Mostrar</option>
        //                             <option value="0" selected={visible_date_review == 0 ? true : false} >Não mostrar</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Páginação</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { set_itens_per_page(event.target.value) }}                        >
        //                             <option value="4" selected={itens_per_page == 4 ? true : false} >4 itens</option>
        //                             <option value="6" selected={itens_per_page == 6 ? true : false} >6 itens</option>
        //                             <option value="8" selected={itens_per_page == 8 ? true : false} >8 itens</option>
        //                             <option value="10" selected={itens_per_page == 10 ? true : false} >10 itens</option>
        //                             <option value="12" selected={itens_per_page == 12 ? true : false} >12 itens</option>
        //                             <option value="14" selected={itens_per_page == 14 ? true : false} >14 itens</option>
        //                             <option value="16" selected={itens_per_page == 16 ? true : false} >16 itens</option>
        //                             <option value="18" selected={itens_per_page == 18 ? true : false} >18 itens</option>
        //                             <option value="20" selected={itens_per_page == 20 ? true : false} >20 itens</option>
        //                             <option value="30" selected={itens_per_page == 30 ? true : false} >30 itens</option>
        //                             <option value="40" selected={itens_per_page == 40 ? true : false} >40 itens</option>
        //                             <option value="50" selected={itens_per_page == 50 ? true : false} >50 itens</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Ordenar avaliações</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { set_order_review(event.target.value) }}                        >
        //                             <option value="default" selected={order_review == "default" ? true : false} >Mais novas</option>
        //                             <option value="tiktok,video,image" selected={order_review == "tiktok,video,image" ? true : false} >Tiktok, Vídeos, Imagens</option>
        //                             <option value="tiktok,image,video" selected={order_review == "tiktok,image,video" ? true : false} >Tiktok, Imagens, Vídeos</option>
        //                             <option value="video,tiktok,image" selected={order_review == "video,tiktok,image" ? true : false} >Vídeos, Tiktok, Imagens</option>
        //                             <option value="video,image,tiktok" selected={order_review == "video,image,tiktok" ? true : false} >Vídeos, Imagens, Tiktok</option>
        //                             <option value="image,video,tiktok" selected={order_review == "image,video,tiktok" ? true : false} >Imagens, Vídeos, Tiktok</option>
        //                             <option value="image,tiktok,video" selected={order_review == "image,tiktok,video" ? true : false} >Imagens, Tiktok, Vídeos</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Ordenar perguntas</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { set_order_ask(event.target.value) }}                        >
        //                             <option value="default" selected={order_ask == "default" ? true : false} >Mais novas</option>
        //                             <option value="answered" selected={order_ask == "answered" ? true : false} >Respondidas</option>
        //                             <option value="pending" selected={order_ask == "pending" ? true : false} >Não respondidas</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Posição estrelas</label>
        //                         <SelectCustom name="select_layout_stars" onChange={(event) => { set_layout_stars(event.target.value) }}                        >
        //                             <option value="" selected={layout_stars == "" || layout_stars == null ? true : false}>Direita</option>
        //                             <option value={layout_stars_centro} selected={layout_stars != "" && layout_stars != null ? true : false}>Centro</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option style={{ position: "relative" }}>
        //                         <label>Termos de uso</label>
        //                         <InputTextAreaCustom
        //                             value={terms_use}
        //                             rows={6}
        //                             maxLength="255"
        //                             placeholder={"Termos de uso"}
        //                             onChange={(e) => { set_terms_use(e.target.value) }}
        //                         />
        //                         <CounterItemsCircle porc={`${Math.round(terms_use?.length / 255 * 100)}%`} colorBorder={terms_use?.length < 255 ? "green" : "red"}>
        //                             <label>{255 - terms_use?.length}</label>
        //                         </CounterItemsCircle>
        //                         {/* <label>{Math.round(request_email_content?.length / 255 * 100)}</label> */}
        //                     </Option>

        //                     <BoxActions>
        //                         <ButtonCustom onClick={() => saveTheme()} type={"save"}>
        //                             <label>Salvar</label>
        //                             <FaCheck color={"#FFFFFF"} size={12} />
        //                         </ButtonCustom>
        //                         <ButtonCustom onClick={() => { getDefaultTheme() }} type={"reset"} >
        //                             <label>Restaurar</label>
        //                             <AiOutlineReload color={"#353535"} size={12} />
        //                         </ButtonCustom>
        //                     </BoxActions>

        //                 </BoxOptions>
        //         }

        //     </Body>
        // </Container>
    )
}

export default BoxConfigTema