
const INITIAL_STATE = {
    error: false,
    loading: false,
    data: []
}

const UpdateConfigHome = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_UPDATE_HOME_REQUEST':
            return { loading: true, error: false, data: [] }
        case 'CONFIG_UPDATE_HOME_ERROR':
            return { loading: false, error: true, data: [] };
        case 'CONFIG_UPDATE_HOME_SUCCESS':
            return { loading: false, error: false, data: action.payload };
        case 'CONFIG_UPDATE_HOME_RESET':
            return { loading: false, error: false, data: [] };
        default:
            return state;
    }
}

export default UpdateConfigHome