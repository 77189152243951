import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.background} ;
    display: flex;
    height: 250px ;
    //width: 100% ;
    width: 48%;
    flex-direction: column ;
    padding: 10px ;
    
    margin-bottom: 5px;
    //max-width: 600px !important;
    //border: 1px solid #DEDEDE; 
    border: ${props => `${props.borderSize} solid ${props.borderColor}`}; 
    border-radius: ${props => props.borderRadius}  ;
    min-width: 300px;

    @media(max-width: 450px) {
        height: auto;
    }

    @media(max-width: 1200px) {
        width: 100% ;
    }

    :hover{
        border: ${props => "1px solid " + props.colorBorder};
        cursor: pointer;
    }
`

export const BoxStars = styled.div`
    //background-color: green ;
    display: flex ;
    justify-content: space-between ;
    align-items: center ;

    div{
        display: flex;
        flex: 1;
        //background-color: red;
        margin-right: 10px;
        //justify-content: ${props => props.position};
        justify-content: ${props => props.position == "default" ? "left" : props.position};

        span{
            //background-color: blue ;
            display: flex ;
            align-items: center ;
        }
    }

    

    label{
        font-size: 14px ;
        color: ${props => props.fontColor};
    }

`

export const BoxContents = styled.div`
    //background-color: orange ;
    display: flex ;
    flex: 1 ;

    @media(max-width: 450px) {
        flex-direction: column-reverse ;
        margin-top: 10px ;
    }
`

export const SectionText = styled.div`
    //background-color: yellow ;
    flex: 1 ;
    padding: 10px 5px 5px 0px;
    color: ${props => props.fontColor};
    font-size: 14px ;
    text-align: justify ;
`

export const SectionMedia = styled.div`
    background-color: white ;
    display: flex ;
    align-items: center ;
    border-radius: 10px;

    @media(max-width: 450px) {
        justify-content: center ;
    }

    img{
        width: 150px ;
        height: 150px ;
        object-fit: contain;
        cursor: pointer;
    }
`

export const Infor = styled.label`
    //background-color: purple ;
    display: flex;
    padding: 5px 0px ;
    font-size: 14px;
    justify-content: center ;
    align-items: center ;
    color: ${props => props.fontColor};
`


export const BadgeCheck = styled.div`
  position: relative;  
  display: flex ;
  //background-color: yellow ;

    svg{
      cursor: initial ;
    }

    :hover{

      label{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
      }
       
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        bottom: 30px;
        right: 0px;
        left: 32px;
        visibility: hidden;
        transform: translateX(-100%);
        /* display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        
        font-size: 10px;
        color: white; */
        
        font-size: 10px;
        color: white;
        font-family: ${props => props.theme.font} !important;

        :after{
       
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        } 
    }
`