import React from 'react'
import { Container, Tooltip } from './styled'
import { AiOutlineYoutube } from "react-icons/ai";
import { BsBook } from "react-icons/bs";
import { getTranslation } from "../../../../translations";

const Options = () => {

    const icon = { size: 18, color: "white" }

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container>
            <label>{translation.dashboard.options.canais}</label>
            <div>
                <span onClick={() => { window.open("https://www.youtube.com/watch?v=AAitCE51W40") }}>
                    <AiOutlineYoutube size={icon.size} color={icon.color} />
                    <Tooltip>
                        <label>{translation.dashboard.options.nossosVideos}</label>
                    </Tooltip>
                </span>

                <span onClick={() => { window.open(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? `https://lily-latam.sak.com.br/faq` : `https://lilyreviews.sak.com.br/faq`) }}>
                    <BsBook size={icon.size} color={icon.color} />
                    <Tooltip>
                        <label>{translation.dashboard.options.faq}</label>
                    </Tooltip>
                </span>
                <span onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }}>
                    <img height={50} width={50} src='https://empreender.nyc3.cdn.digitaloceanspaces.com/static/SAK/in/d9db71e79a62.svg' />
                    <Tooltip>
                        <label>{translation.dashboard.options.suporte}</label>
                    </Tooltip>
                </span>
            </div>
        </Container>
    )
}

export default Options