import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import { getTranslation } from "../../../../translations";

const LoginPlus = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const statuslogin = useSelector(state => state.reducerLogin)

  const translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");

  useEffect(() => {
    if (!(statuslogin.loading === false && statuslogin.error === false && statuslogin.data.length === 0)) {

      if (statuslogin.loading === true) {
        toast.loading(translation.login.loginPlus.aguarde, { toastId: 0 })
      } else {
        if (statuslogin.error === true) {
          toast.update(0, { render: translation.login.loginPlus.errorLogin, type: "error", isLoading: false, autoClose: 5000 });
          history.push("/login")
        } else if (statuslogin.error === false) {
          toast.update(0, { render: translation.login.loginPlus.loginSucesso, type: "success", isLoading: false, autoClose: 2000 });

          if (statuslogin.data.data.user.isAdmin == 1) {
            history.push("/admin")
          } else {
            history.push("/")
          }
        }
      }
    }
  }, [statuslogin])

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search))

    if (searchParams?.code) {
      dispatch({ type: "SAGA_LOGAR_PLUS_CONCLUIR", payload: searchParams })
    } else {
      history.push("/login")
    }
  }, [history, dispatch, location])

  return (
    <>
      {isVisibleScript(false)}
    </>
  )
}

export default LoginPlus
