import { useSelector } from "react-redux";
import { Container } from './styled'
import { formatTimestamp, formatDate } from "../../../../utils/date";
import { getTranslation } from "../../../../translations";

const FormaPagamentoInfo = () => {
    const payments = useSelector(state => state.reducerPayments)
    const user = payments.data.user

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container>
            <div>
                <h3>{translation.assinatura.formaPagamentoInfo.dataInstalacao}</h3>
                <span>{user?.created_at ? formatDate(user.created_at, "dd/MM/yyyy") : '-'}</span>
            </div>
            <div>
                <h3>{translation.assinatura.formaPagamentoInfo.ultimoPagamento}</h3>
                <span>{user?.cred_last_date ? formatTimestamp(user.cred_last_date, "dd/MM/yyyy") : '-'}</span>
            </div>
            <div>
                <h3>{translation.assinatura.formaPagamentoInfo.proximaFatura}</h3>
                <span>{user?.cred_expire ? formatTimestamp(user.cred_expire, "dd/MM/yyyy") : '-'}</span>
            </div>
        </Container>
    );
}

export default FormaPagamentoInfo