import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    background-color: white;
    width: 500px;
    padding: 30px !important;
    margin-bottom: 50px;
    border-radius: 10px;
    cursor: pointer;
    flex-direction: column;
    height: 100%;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

`;

export const Box = styled.label`
    background-color: #EC613515; //#F7F8FA;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
`

export const Title = styled.label`
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0px 10px;
    color:  #EC6135; //gray; //#FE6622; //#181C32;
    font-weight: bold;
`

export const Item = styled.div`
    height: 25px;
    display: flex;
    align-items: center;
    padding: 0px 10px;

`

export const SectionLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    
    a{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        color: gray;// #FE6622;//  #181C32;
        text-align: right;
        cursor: pointer;
        text-decoration: none;
        padding-right: 5px;
        font-weight: bold;

        :hover{
            text-decoration: underline;
        }


    }

`

export const SectionRight= styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content:  left !important;
`

export const Bar = styled.div`
    background-color: #EC6135; //#FE6622;//#181C32;
    height: 20px;
    //display: flex;
    flex: ${props => props.bar};
    justify-content: left;
    min-width: 15px;
    
    label{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5px;
        font-size: 10px;
        padding-top: 2px;
        height: 100%;
        color: #F7F8FA;
    }
`