import styled from "styled-components";


export const ContainerInfor = styled.div`
  display: flex;
  height: 25px;
  margin-bottom: 0px;
  align-items: center;
  padding: 0px 10px;

  label {
    font-size: 14px;
    width: 25px;
    text-align: center;
    font-family: ${props => props.theme.font} !important;
    color: ${props => props.theme.font_color_primary} ;
  }

  span {
    //background-color: #d7d7d7;
    height: 5px;
    display: flex;
    flex: 1;
    margin: 0px 10px;
    border-radius: 4px;
    background:  #d7d7d7;
    position: relative;

    div{
      width: ${props => `${props.porcent}%`};
      min-height: 5px;
      background-color: ${props => props.color};
      position: absolute;
      border-radius: 4px;
    }
  }

  p{
    display: flex;
    //background-color: gray;
    margin: 0px;
    padding: 0px;
    margin-right: 5px;
    font-weight: 700;
    width: 10px;
    justify-content: end;
  }
`;

export const Container = styled.div`
  display: flex;
  //background-color: red;
  margin: 10px 0px 20px 0px;
  justify-content: end;
`

export const Number = styled.div`
  display: flex;
  //background-color: red;
  //height: 100%;
  align-items: center;
  margin-right: 10px;

  span{
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background-color: ${props => props.background};
    color: ${props => props.color};
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 400;
    font-family: ${props => props.font} ;
  }
`

export const InforResume = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
`