import { useHistory, useLocation } from "react-router-dom";
import { LabelMenu, Container, Body, SectionLeft, SectionRight, Rodape, BoxOptions, Contents, SelectCustomOptions, SelectCustom } from "./styled";
import Logo from "../../../../assets/imgs/logo-login.svg";
import { useEffect, useState } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import { getTranslation } from "../../../../translations";

import { MdKeyboardArrowDown } from "react-icons/md";
import imgBrasil from "../../../../assets/imgs/brasil.svg";
import imgArgentina from "../../../../assets/imgs/argentina.svg";
import imgMexico from "../../../../assets/imgs/mexico.svg";

import imgChile from "../../../../assets/imgs/chile.svg";
import imgColombia from "../../../../assets/imgs/colombia.svg";

import { useDispatch } from "react-redux";

const Page = (props) => {

    const selectedMenu = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()

    const [selectIsOpen, setSelectIsOpen] = useState(false)
    const [selectValue, setSelectValue] = useState(localStorage.getItem("depoimentos@pais") != undefined && localStorage.getItem("depoimentos@pais") != null ? localStorage.getItem("depoimentos@pais") : window.navigator.language.substring(window.navigator.language.length - 2))

    const translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");



    // useEffect(() => {
    //     document.getElementById('wa__btn_popup_chat').style.display = "none";
    //     if (document.getElementById('wa__btn_popup_chat') != null) {
    //         document.getElementById('wa__btn_popup_chat').style.zIndex = "-99999";
    //     }
    //     if (document.getElementById('productstashSelector') != null) {
    //         document.getElementById('productstashSelector').style.display = "none";
    //     }
    // }, [selectedMenu])
    // useEffect(() => {
    //     document.getElementById('wa__btn_popup_chat').style.display = "none";
    //     if (document.getElementById('wa__btn_popup_chat') != null) {
    //         document.getElementById('wa__btn_popup_chat').style.zIndex = "-999999";
    //     }
    //     if (document.getElementById('productstashSelector') != null) {
    //         document.getElementById('productstashSelector').style.display = "none";
    //     }
    // }, [])

    useEffect(() => {
        if (selectIsOpen) {
            window.addEventListener('mouseup', closeMenu);
            return () => {
                window.removeEventListener('mouseup', closeMenu);
            };
        }
    }, [selectIsOpen])

    const closeMenu = () => {
        setSelectIsOpen(false)
    };

    useEffect(() => {
        closeMenu()
        localStorage.setItem("depoimentos@pais", selectValue)
        dispatch({ type: "LOGIN_CONTROL_LANGUAGE_SET", payload: selectValue })
    }, [selectValue])

    const getImg = (value) => {

        switch (value) {
            case "MX":
                return <img src={imgMexico} />
            case "CO":
                return <img src={imgColombia} />
            case "AR":
                return <img src={imgArgentina} />
            case "CL":
                return <img src={imgChile} />
            case "BR":
                return <img src={imgBrasil} />
            default:
                return <img src={imgBrasil} />
        }
    }

    return (
        <Container>
            <Body>
                <SectionLeft>
                    <div>
                        <img src={Logo} />
                    </div>
                    {/* <span>
                        <label onClick={() => { window.open('https://empreender.com.br/', '_blank'); }}>EMPREENDER</label>
                    </span> */}
                    <a href="https://empreender.com.br/" style={{ display: "flex", width: "fit-content", margin: "11px auto", padding: "0" }} target="_blank" rel="noreferrer">
                        <img src="https://empreender.nyc3.cdn.digitaloceanspaces.com/static/empreender.svg" alt="empreender-logo" style={{ position: "unset", width: "fit-content", height: "9px", margin: "0" }}></img>
                    </a>
                </SectionLeft>
                <SectionRight>
                    <BoxOptions onClick={() => { setSelectIsOpen(true) }}>
                        <span style={{ display: "flex", flex: "1", alignItems: "center" }} >
                            <LabelMenu onClick={() => { history.push("/login") }} selectedMenu={selectedMenu.pathname === "/login" ? true : false}>{translation.login.login}</LabelMenu>
                            <LabelMenu onClick={() => { history.push("/cadastro") }} selectedMenu={selectedMenu.pathname === "/cadastro" ? true : false}>{translation.login.registrar}</LabelMenu>
                        </span>
                        <SelectCustom>
                            {/* <img src={selectValue == "AR" ? imgArgentina : selectValue == "MX" ? imgMexico : imgBrasil} /> */}
                            {getImg(selectValue)}
                            <span />
                            <MdKeyboardArrowDown />
                            <SelectCustomOptions isOpen={selectIsOpen}>
                                <span onClick={() => { setSelectValue("BR") }}>
                                    <img src={imgBrasil} />
                                </span>
                                <span onClick={() => { setSelectValue("AR") }}>
                                    <img src={imgArgentina} />
                                </span>
                                <span onClick={() => { setSelectValue("MX") }}>
                                    <img src={imgMexico} />
                                </span>
                                <span onClick={() => { setSelectValue("CL") }}>
                                    <img src={imgChile} />
                                </span>
                                <span onClick={() => { setSelectValue("CO") }}>
                                    <img src={imgColombia} />
                                </span>
                            </SelectCustomOptions>
                        </SelectCustom>

                    </BoxOptions>
                    <Contents>
                        {props.children}
                    </Contents>
                    <Rodape>
                        <a className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" href="https://www.iubenda.com/privacy-policy/88589789" title="Política de Privacidade" >{translation.login.politicaPrivacidade}</a>
                        <a href="https://www.iubenda.com/privacy-policy/88589789/cookie-policy" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" title="Política de Cookies">{translation.login.politicaCookie}</a>
                        <a href="https://www.iubenda.com/termos-e-condicoes/88589789" className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" title="Termos e Condições">{translation.login.termosCondicoes}</a>
                    </Rodape>
                </SectionRight>
            </Body>
            {isVisibleScript(false)}
        </Container>
    )
}

export default Page
