import InputCustom from "../../components/InputCustom";
import Page from "../../components/Page";
import Btn from "../../components/Btn";
import { FormPassword, Actions } from "./styled";
import { AiFillMail } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import ErrorMessage from "../../components/ErrorInput";
import { useState } from "react";
import { useEffect } from "react";
import { isVisibleScript } from "../../../../utils/displayScript";
import { getTranslation } from "../../../../translations";

const Password = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const statusPassword = useSelector(state => state.reducerPassword)
    const language = useSelector(state => state.reducerControlLanguage)
    const [id, setId] = useState(null)

    let translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");

    useEffect(() => {
        //console.log("teste language localstorage", language, localStorage.getItem("depoimentos@pais"))
        translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");
    }, [language])
    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object({
            email: Yup.string().required(translation.login.password.formik.email.validacao1).email(translation.login.password.formik.email.validacao2).max(200, translation.login.password.formik.email.validacao3).min(3, translation.login.password.formik.email.validacao4),
        }),
        onSubmit: (values) => { dispatch({ type: "SAGA_PASSWORD_LOGIN", payload: values }) },
    });

    useEffect(() => {
        if (!(statusPassword.loading === false && statusPassword.error === false && statusPassword.data.length === 0)) {
            if (statusPassword.loading === true) {
                setId(toast.loading(translation.login.password.formik.aguarde, { toastId: 0 }))
            } else {
                if (statusPassword.error === true) {
                    toast.update(id, { render: translation.login.password.errorAcesso, type: "error", isLoading: false, autoClose: 5000 });
                } else if (statusPassword.error === false) {
                    toast.update(id, { render: translation.login.password.novaSenha, type: "success", isLoading: false, autoClose: 2000 });
                    formik.resetForm()
                    history.push("/")
                }
            }
        }

    }, [statusPassword])

    useEffect(() => {
        if (localStorage.getItem("depoimentos@login")) {
            history.push("/")
        }
    }, [])

    return (
        <Page>
            <FormPassword onSubmit={formik.handleSubmit}>
                <InputCustom>
                    <span>
                        <AiFillMail size={20} color={"gray"} />
                    </span>
                    <input name={"email"} onChange={formik.handleChange} value={formik.values.email} type={"text"} placeholder={translation.login.password.email} />
                </InputCustom>
                {formik.errors.email && formik.touched.email && <ErrorMessage>{formik.errors.email}</ErrorMessage>}

                <Actions>
                    <Btn type="submit" label={translation.login.password.recuperarSenha}
                        onClick={() => { if (formik.dirty === false) toast.warning(translation.login.password.preencherDados, { toastId: 0 }) }}
                    />
                    <label onClick={() => { history.push("/login") }}>{translation.login.password.voltar}</label>
                </Actions>
            </FormPassword>
            {isVisibleScript(false)}
        </Page>
    )
}

export default Password
