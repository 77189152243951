import { useState } from 'react'

import { ContainerForm, InputContainer, SelectContainer } from './styled'
import { useFormik } from "formik";
import * as Yup from 'yup';
import Input from '../../../../components/Input/index'
import Button from '../../../../components/Button/index'
import { toast } from 'react-toastify';
import { POST_ERROR } from '../../../../services/api';
import { getTranslation } from '../../../../translations';

const DadosNotaFiscal = ({ dadosFiscais, listaUfs }) => {

    const [isCnpj, setIsCnpj] = useState(false);

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            cpj_cpf: dadosFiscais.cpf_cnpj ?? "",
            nome: dadosFiscais.name ?? "",
            email: dadosFiscais.email ?? "",
            telefone: dadosFiscais.phone ?? "",
            cep: dadosFiscais.zip ?? "",
            bairro: dadosFiscais.district ?? "",
            endereco: dadosFiscais.address ?? "",
            cidade: dadosFiscais.city ?? "",
            numero: dadosFiscais.number ?? "",
            complemento: dadosFiscais.complement ?? "",
            uf: dadosFiscais.uf ?? "0",
        },
        // validationSchema: Yup.object({
        //   email: Yup.string().required("Preencher o campo E-mail.").email("Preencha um e-mail valido.").max(200, "Máximo de 200 caracteres.").min(3, "Minimo de 8 caracteres."),
        //   password: Yup.string().required("Preencher o campo Senha.").min(8, "Minimo de 8 caracteres.").max(200, "Maximo de 200 caracteres."),
        // }),
        onSubmit: async (values) => {
            const id = 0
            toast.loading(translation.assinatura.dadosNotaFiscal.aguarde, { toastId: id });
            let { data } = await POST_ERROR({
                endpoint: "/payments/tax-user",
                data: values
            });

            toast.update(id, { render: data.description ?? translation.assinatura.dadosNotaFiscal.erroInesperado, type: data.error ? "error" : "success", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
        },
    });

    return (
        <ContainerForm onSubmit={formik.handleSubmit}>
            <h3>{translation.assinatura.dadosNotaFiscal.dadosFiscal}</h3>
            <InputContainer>
                <Input name={"cpj_cpf"} value={formik.values.cpj_cpf} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.cpnjCpf} isCnpj={(val) => setIsCnpj(val)} placeholder={translation.assinatura.dadosNotaFiscal.cpnjCpf} mask="doc" />
                <Input name={"nome"} value={formik.values.nome} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.nomeRazao} placeholder={translation.assinatura.dadosNotaFiscal.nomeRazao} />
            </InputContainer>
            <InputContainer>
                <Input name={"email"} value={formik.values.email} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.email} placeholder={translation.assinatura.dadosNotaFiscal.email} />
                <Input name={"telefone"} value={formik.values.telefone} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.telefone} placeholder={translation.assinatura.dadosNotaFiscal.telefone} mask="(99) 9999-9999" />
            </InputContainer>

            {isCnpj &&
                <div>
                    <InputContainer>
                        <Input name={"cep"} value={formik.values.cep} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.cep} placeholder={translation.assinatura.dadosNotaFiscal.cep} mask="99999999" />
                        <Input name={"bairro"} value={formik.values.bairro} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.bairro} placeholder={translation.assinatura.dadosNotaFiscal.bairro} />
                        <Input name={"endereco"} value={formik.values.endereco} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.endereco} placeholder={translation.assinatura.dadosNotaFiscal.endereco} />
                    </InputContainer>
                    <InputContainer>
                        <Input name={"cidade"} value={formik.values.cidade} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.cidade} placeholder={translation.assinatura.dadosNotaFiscal.cidade} />
                        <SelectContainer>
                            <label>{translation.assinatura.dadosNotaFiscal.estado}</label>
                            <select name={"uf"} defaultValue={formik.values.uf} onChange={formik.handleChange}>
                                {listaUfs.map((item, key) => (
                                    <option key={key} value={key}>{item}</option>
                                ))}
                            </select>
                        </SelectContainer>
                        <Input name={"numero"} value={formik.values.numero} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.numero} placeholder={translation.assinatura.dadosNotaFiscal.numero} />
                        <Input name={"complemento"} value={formik.values.complemento} handleChange={formik.handleChange} label={translation.assinatura.dadosNotaFiscal.complemento} placeholder={translation.assinatura.dadosNotaFiscal.complemento} />
                    </InputContainer>
                </div>
            }
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button type="submit" width="250px" marginTop="30px" background="#ffcc00" color="#5e0a07">{translation.assinatura.dadosNotaFiscal.atualizarDados}</Button>
            </div>

        </ContainerForm>
    );
}

export default DadosNotaFiscal