import styled from "styled-components";


export const BoxFormLogin =  styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  //background-color: purple;

  span{
    display: flex;
    align-items: center;
    justify-content: space-between;

    label{
      cursor: pointer;
      color: gray;

      :hover{
        text-decoration-line: underline;
      }
    }
  }

`;

export const BoxEmpreenderPlusLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 2rem;

  .btn-logar-plus {
    background-color: #4e0cb3;
    color: white !important;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    padding: 14px;
    border-radius: 5px;
  }

  .btn-logar-plus:hover {
    background-color: #340083;
  }

  & > span > strong {
    cursor: pointer;
  }
`;


export const BoxSocials =  styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px 0px; 
  padding: 0px !important ;

`;

export const BoxSocialsTitle = styled.div`
  display: flex;
  align-items: center;

  span{
    display: flex;
    flex: 1;
    height: 1px !important;
    background-color: gray;
  }
  label{
    margin: 0px 20px;
    color: gray;
  }
`;



export const BtnSocial = styled.div`
  display: flex;
  background-color: ${props => props.background};
  width: 110px;
  height: 35px;
  border-radius: 20px;
  justify-content: flex-start;
  align-items: center;
  cursor: ${props => props.cursor};
  padding: 0px 15px ;

  label{
    margin-left: 10px;
    cursor: ${props => props.cursor};
    color: white;
    font-size: 12px;
    flex: 1;
    text-align: center;
  }

  span{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`; 

export const BoxSocialsBtn = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;

  @media(max-width: 360px) {
    justify-content: space-around;
    padding: 10px 10px
  }
`; 

export const BoxSocialsInfor = styled.div`
  display: flex;
  justify-content: space-evenly;

  label{
    color: gray;
    
    strong {
      color: #20d489;
      cursor: pointer;
      margin: 0px 10px;
      :hover{
        text-decoration-line: underline;
      }
    }
  }
`; 

