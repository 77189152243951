import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.layout.secondary};
    width: 100%;
    //height: 100%;
    display: flex;
    min-height: 100vh;

    overflow: hidden; 
    //background-color: purple;
    //padding-top: 45px ;
`;