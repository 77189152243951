import { CloseIcon, Content, Header } from './styled'
import Modal from 'react-modal';
import { usePagamento } from '../../hooks/usePagamento';
import { RiCloseLine } from 'react-icons/ri';
import Button from '../../../../components/Button/index';
import { AiOutlineLoading } from "react-icons/ai";
import { useState } from 'react'
import { getTranslation } from '../../../../translations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '31px',
        borderRadius: '10px',
        width: '450px',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalPix = () => {
    const { handleTogglePix, modalPixIsOpen, gerarPix, isLoading } = usePagamento();
    const [linkPix, setLinkPix] = useState(false)

    function closeModal() {
        handleTogglePix(false);
    }

    async function handleClick() {
        if (isLoading) return

        const response = await gerarPix()
        setLinkPix(response)
    }

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Modal
            isOpen={modalPixIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <CloseIcon onClick={closeModal}><RiCloseLine /></CloseIcon>
            <Header>
                <strong>{translation.assinatura.modalPix.falhaTransacao}</strong>
                <small>{translation.assinatura.modalPix.cliqueBotao}</small>
            </Header>
            <Content>
                {linkPix ?
                    <a href={linkPix} target="_blank" rel="noreferrer">{translation.assinatura.modalPix.pagarPix}</a>
                    :
                    <Button handleClick={handleClick} width="100%" marginTop="16px" background="#20d489" color="#fff">{isLoading ? <AiOutlineLoading className="spinner" size={25} color={"#fff"} /> : translation.assinatura.modalPix.gerarPix}</Button>
                }
            </Content>
        </Modal>
    );
}

export default ModalPix