import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 450px;
    padding: 25px;
    border-radius: 5px;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;

export const FormCustom = styled.div`
    display: flex;
    //background-color: whitesmoke;
    flex-direction: column;
`;

export const ButtonCustom = styled.button`
    display: flex;
    background-color: #ffcc00;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #5e0a07;
    font-weight: 600;
    cursor: pointer;
    :hover{
        opacity: 0.7;
    }
`;
export const BoxInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    margin-bottom: 10px;

    label{
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;
    }

`;

export const InputCustomText = styled.span`
    background-color: #EFEFEF;
    display: flex;
    flex-direction: row;
    height: 40px;
    border-radius: 5px;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
    }

    input{
        display: flex;
        flex: 1;
        outline: none;
        border: none;
        background-color: transparent;
        padding-right: 10px;
        //text-transform: uppercase;
        font-size: 12px;
    }
`;

export const InputCustomDate = styled.input`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #EFEFEF;
    padding-right: 10px;
    height: 100px;
    border-radius: 5px;
    padding-left: 10px;
`;


export const TextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #EFEFEF;
    padding-right: 10px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    font-size: 12px ;
`;

export const Error = styled.label`
    font-size: 10px !important;
    color: red;
    margin-left: 10px;
`;

export const CheckCustom = styled.div`
    display: flex;
    height: 40px;
    align-items: center;
    label{
        margin-left: 5px;
    }
`;
