import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    
    flex-direction: column;
    width: 500px;
    min-height: 150px;
    padding: 10px;
    border-radius: 5px;

`;

export const Actions = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;

    button{
        width: 100px;
        height: 30px;
        margin-left: 10px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #19B674;
        cursor: pointer;
        color: white;

        :hover{
            opacity: 0.8;
        }
        
    }
    button:nth-child(1){
        background-color: transparent !important;
        border: 2px solid #19B674;
        color: black;
        //font-weight: bold;
    }
`;


export const AlertSave = styled.div`
    background-color: #242527 ;
    display: flex ;
    width: 700px ;
    bottom: 100px;
    ///position: fixed ;
    height: 80px ;
    align-items: center ;
    padding: 0px 20px ;
    border-radius: 4px ;
    gap: 10px;

    img{
        width: 44px ;
        height: 44px ;
    }

    span{
        display: flex ;
        flex: 1;
        //background-color: yellow ;
        justify-content: space-between ;

        label{
            font-size: 14px ;
            display: flex ;
            align-items: center ;
            font-size: 14px ;
            color: #fff;

        }

        div{
            display: flex ;
            gap: 20px;
            align-items: center ;

            label{
                font-size: 14px ;
                height: 35px ;
                border: 1px solid #FFFFFF;
                border-radius: 4px;
                display: flex ;
                align-items: center ;
                padding: 0px 10px ;
                color: white ;
                font-size: 14px  !important;
                cursor: pointer;
            }

            span{
                background-color: #00803B ;
                border: none ;
                outline: none ;
                cursor: pointer;
                height: 35px ;
                border-radius: 4px ;
                width: 90px ;
                color: white ;
                font-size: 14px ;
                display: flex ;
                align-items: center ;
                justify-content: center ;
            }
        }
    }
`