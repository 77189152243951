import { useSelector } from "react-redux"
import { Container } from "./styled"

const Contents = (props) => {

    const menuOpen = useSelector(state => state.reducerControlMenu)



    return (
        <Container padding={props.padding} menuOpen={menuOpen.isOpen}>
            {props.children}
        </Container>
    )
}

export default Contents
