import styled from 'styled-components'


export const Card = styled.div`

    width: 350px;
    height: 430px;
    animation: Skeleton 1s ease infinite alternate;
    background-color: #f1f1f1;
    border-radius: 5px ;
    display: flex ;
    flex-direction: column;
    padding: 33px;
    gap: 20px;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const Title = styled.div`
    background-color: #dcdcdc;
    height: 40px ;
    //width: 100% ;
    margin: 0px 50px ;
    border-radius: 5px ;

`

export const SubTitle = styled.div`
    background-color: #dcdcdc;
    height: 40px ;
    //width: 100% ;
    margin: 0px 50px ;
    border-radius: 5px ;
`

export const Options = styled.div`
    display: flex ;
    flex: 1 ;
    flex-direction: column ;
    gap: 20px;
    justify-content: center ;
    //background-color: red;

    span{
        background-color: #dcdcdc;
        height: 20px ;
        width: 100% ;
        border-radius: 5px ;
    }

`
   
export const ButtonCustom = styled.div`
    background-color: #dcdcdc ;
    width: 100%;
    height: 50px;
    border-radius: 5px ;
    
`
