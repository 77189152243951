import React from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../translations'
import { Container, Contents, Row, RowCustom, Title } from './styled'

const TableEmailsSend = () => {

  const dashboard = useSelector(state => state.reducerDashboard)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  return (
    <Container>
      <Title>
        <label>{translation.dashboard.tableEmailSend.emailEnviado}</label>
      </Title>
      <Contents>
        <RowCustom> #
          <label style={{}}>{translation.dashboard.tableEmailSend.email}</label>
          <label>{translation.dashboard.tableEmailSend.pedido}</label>
          <label>{translation.dashboard.tableEmailSend.enviado}</label>
        </RowCustom>
        {/* <RowCustom>0
          <>
            <label>
              renatarosanellef@gmail.com
            </label>
            <label >
              695429978
            </label>
            <strong>04/09/2022</strong>
          </>
        </RowCustom>
        <RowCustom>0
          <>
            <label>
              lilianes826@gmail.com
            </label>
            <label >
              695887583
            </label>
            <strong>03/09/2022</strong>
          </>
        </RowCustom>
        <RowCustom>0
          <>
            <label>
              fantinybinha12@gmail.com
            </label>
            <label >
              696775216
            </label>
            <strong>04/09/2022</strong>
          </>
        </RowCustom> */}
        <RowCustom>1
          {dashboard?.data?.emailsSent?.length > 0 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[0]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[0]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[0]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>2
          {dashboard?.data?.emailsSent?.length > 1 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[1]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[1]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[1]?.updated_at).toLocaleDateString('pt-BR')}</strong>

            </>
          }
        </RowCustom>
        <RowCustom>3
          {dashboard?.data?.emailsSent?.length > 2 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[2]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[2]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[2]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>4
          {dashboard?.data?.emailsSent?.length > 3 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[3]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[3]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[3]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>5
          {dashboard?.data?.emailsSent?.length > 4 &&
            <>
              <label>
                {dashboard?.data?.emailsSent[4]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[4]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[4]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>6
          {dashboard?.data?.emailsSent?.length > 5 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[5]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[5]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[5]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>7
          {dashboard?.data?.emailsSent?.length > 6 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[6]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[6]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[6]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>8
          {dashboard?.data?.emailsSent?.length > 7 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[7]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[7]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[7]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>9
          {dashboard?.data?.emailsSent?.length > 8 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[8]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[8]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[8]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>10
          {dashboard?.data?.emailsSent?.length > 9 &&
            <>
              <label >
                {dashboard?.data?.emailsSent[9]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsSent[9]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsSent[9]?.updated_at).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
      </Contents>
    </Container>
  )
}

export default TableEmailsSend