
const INITIAL_STATE = {isOpen: false}

const ControlModalReviews = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCTS_CONTROL_MODAL_REVIEWS':
            return state = action.payload
        default:
            return state;
    }
}

export default ControlModalReviews