
const INITIAL_STATE = true

const ControlSaveHomeConfig = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_SAVE_HOME_CONFIG_SET':
            return action.payload
        default:
            return state;
    }
}

export default ControlSaveHomeConfig