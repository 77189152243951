import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
    //background-color: orange ;
`;

export const BoxAlert = styled.div`
    margin: 30px 30px 0px 280px ;

    @media screen and (max-width: 768px) {
        margin: 0px 30px !important;
    }
`;

export const Filters = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    flex-wrap: wrap;
    //background-color: red;

    svg{
        cursor: pointer;
    }

    span{

        position: relative ;
        :hover{

            label{
                visibility: visible ;
            }
        }

        label{
            display: flex;
            position: absolute;
            background: #292929cc;
            visibility: hidden;
            border-radius: 5px;
            //max-width: 300px !important; 
            width: max-content;
            padding: 10px 10px;
            transform: translateX(-100%);
            left: 0;
            margin-top: -85px;
            margin-left: 38px;
            color: white ;
            font-size: 11px !important;

            :after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border-top: 8px solid #292929cc;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
            }
        }
    }

    select{
        outline: none;
        border-radius: 5px;
        height: 40px;
        padding: 0px 10px;
        cursor: pointer;
        border: none;
        background-color: ${props => props.theme.colors.layout.secondary};
        color: #A1A5B6;
        min-width: 150px;
   }

   button{
       height: 40px;
       width: 100px;
       outline: none;
       border: none;
       background-color: ${props => props.theme.colors.layout.secondary} !important;
       border-radius: 5px;
       color: gray;
       cursor: pointer;
       border: 1px solid #d3d3d3;
   }
`;

export const SectionLeft = styled.div`
    display: flex;
    margin: 10px 0px;
`;

export const SectionRight = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
`;

export const Search = styled.div`
    
    background-color: ${props => props.theme.colors.layout.secondary};
    display: flex;
    height: 40px;
    //border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    
    span{
        cursor: pointer;
    }

    input{
        display: flex;
        height: 100%;
        width: 250px;
        outline: none;
        border: none;
        background-color: transparent;
    }
`;

export const Check = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    color: gray;
    span{
        display: flex;
        width: 20px;
        height: 20px;
        background-color: #FBF9F6;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        margin-right: 5px ;
        border-radius: 4px;
    }
`;

export const DropDown = styled.div`
    display: flex;
    background-color: orange;

    span{
        display: none;
        flex-direction: column;
    }
`;

export const Infor = styled.div`
    //min-height: 50px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
  
    label{
        margin-right: 10px; 
        margin-top: 75px;

        strong{
            cursor: pointer;
            :hover{
                text-decoration: underline;
            }
        }
    }

    label:nth-last-child(1){
        margin-top: 0px;
        font-weight: 500;
        font-size: 18px;
    }

    label:nth-last-child(2){
        font-weight: 700;
        font-size: 44px;
        //background-color: red ;
    }
    /* button{
        display: flex;
        height: 35px;
        background-color:  yellow;
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        color: black;
        border: 1px solid gray;
        width: 150px;

        div{
            background-color: #FBF9F6;
            width: 200px;
            position: absolute;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 9999;
            padding-left: 5px;
            border: 1px solid gray;
            border-radius: 5px;

            label{
                //background-color: peru;
                text-align: left;
                width: 100%;
                cursor: pointer;
                height: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: black;
                
                
                :hover{
                    opacity: 0.5;
                }
            }
        } 
    } */

    
`;

export const ListCard = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    //background-color: red ;
    gap: 20px;
    padding-top: 30px ;
`;

export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 50px ;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const ButtonAdd = styled.div`
    background-color: #54D18C;
    display: flex;
    height: 35px;
    border-radius: 5px;
    justify-content: center;
    color: white;
    padding: 0px 20px;
    cursor: pointer;
    //width: 110px;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: tomato;
        margin-left: -5px;
        cursor: pointer;
    }
    label{
        display: flex;
        align-items: center;
        cursor: pointer;
        //background-color: violet;
        font-size: 12px ;
    }

    //align-items: center;
`;

export const Paginate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PaginateNav = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 5px;
    align-items: center;

    span{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    label{
        margin: 0px 10px;
    }
`;

export const PaginateInfor = styled.div`
    display: flex;
    //background-color: saddlebrown;
`;

export const BoxInforInitial = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px ;
`;

export const BoxInforInitialLeft = styled.div`
    display: flex;
    //background-color: #808080;
    flex-direction: column ;
    flex: 1;

    label{

    }
    label:nth-child(1){
        font-weight: 600;
        font-size: 28px;
        color: #5E6278;
    }

    label:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #5E6278;
    }
`;

export const BoxInforInitialRight = styled.div`
    display: flex;
    
    width: 582px;
    align-items: center ;
    justify-content: center ;
    
    label{
        background-color: #FFC400;
        display: flex ;
        padding: 8px ;
        border-radius: 4px ;
        font-size: 12px ;
        font-weight: 400;
        font-size: 14px;
        color: #2b2b2b;
        cursor: pointer;
    }

`;

export const ContainerInfor = styled.div`
    //background-color: red;
    display: flex ;
    flex-direction: column ;
`

export const BoxMessage = styled.div`
    margin-top: 100px ;
    //background-color: yellow;
    display: flex ;
    flex-direction: column ;

    label{
        //background-color: red ;
    }
`

export const NavInfor = styled.strong`
    cursor: pointer;
    margin: 0px 0px ;
    :hover{
        text-decoration: underline;
    }
`;