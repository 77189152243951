import styled from "styled-components";

export const Container = styled.button`
    background-color: ${props => props.theme.colors.font.active};
    display: flex;
    height: 40px;
    border-radius: 20px;
    padding: 0px 25px;
    cursor: pointer;
    align-items: center;
    border: none;
    color: white;

    :hover{
        opacity: 0.7;
    }
`;