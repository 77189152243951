
const INITIAL_STATE = true

const ControlSaveEmailConfig = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET':
            return action.payload
        default:
            return state;
    }
}

export default ControlSaveEmailConfig