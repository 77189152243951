
const INITIAL_STATE = ""

const ControlEdit = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_EDIT_SET':
            return action.payload
        case 'CONFIG_CONTROL_EDIT_GET':
            return state;
        default:
            return state;
    }
}

export default ControlEdit