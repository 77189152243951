import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.layout.primary};
    width: ${props => props.menuOpen ? "200px" : "72px"};
    height: 100%;
    //display: flex;
    transition: 400ms ease;
    //position: relative;
    //overflow: auto;
    position: fixed ;
    //top: 35px;
    //padding-bottom: 35px ; 
    border-right: 1px solid #D3DAE3;
    z-index: 1;
    transition: 0.5s ease-in;
    //background-color: red ;
    
    /* @media screen and (max-width: 768px) {
        position: absolute;
        top: 0;
        z-index: 99999;
        //background-color: red ;
        position: fixed ;
    } */

    /* @media screen and (max-width: 550px) {
        display: ${props => props.menuOpen ? "inicial" : "none"};
        width: ${props => props.menuOpen ? "72px" : "0px"};
    } */
`;

export const SectionLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    //background-color: red ;
    height: ${props => props.menuOpen ? "130px" : "80px"}; //50px;;
    align-items: center ;
    //background-color: yellow ;





        img{
            width: ${props => props.menuOpen ? "80px" : "40px"}; //40px;;
        }

        video{
            max-height: ${props => props.menuOpen ? "80px" : "55px"}; //40px;;
        }

     
`;

export const SectionMenu = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 0px 20px;
    //padding-left: 2.5rem;
    margin: 10px 10px;
    //background-color: yellow ;
    align-items: center ;
    gap: 10px;


`;



export const ItemMenu = styled.div`
    display: flex;
    align-items: center;
    //margin-bottom: 19px;
    cursor: pointer;
    background-color: ${props => props.menuOpen && props.selectedMenu ? "#FFC400" : "transparent"} ;
    border-radius: ${props => props.selectedMenu && props.menuOpen ? "20px" : "0px"}  ; ;
    padding-top:  5px;
    padding-bottom: 5px ;
    width: 100%;
    justify-content: ${props => props.menuOpen ? "left" : "center"} ;
    border-left: ${props => props.selectedMenu && !props.menuOpen ? "3px solid #FFC400" : "none"}  ;

    position: ${props => !props.menuOpen ? "relative" : "Inicial"} ; ; ; 
    gap: 10px;
    //padding-left: ${props => props.menuOpen ? "5px" : "0px"} ;
    padding-left: ${props => props.selectedMenu && props.menuOpen ? "8px" : "5px"} ;
    margin-left: ${props => props.selectedMenu && props.menuOpen ? "3px" : props.selectedMenu ? "5px" : "8px"} ;

    //background-color: red;


    img{
        height: 24px ;
        width: 24px ;
    }
    label{
        //display: ${props => !props.menuOpen ? "none" : "flex"} ;
        opacity: ${props => !props.menuOpen ? "0" : "1"};
        visibility: ${props => !props.menuOpen ? "hidden" : "visibility"} ;
        position: ${props => !props.menuOpen ? "absolute" : "inicial"} ;
        font-size: 13px ;
        cursor: pointer;
        color: ${props => props.selectedMenu && "#000000"};
        transition: opacity 1s ease-in;
  
    }

    span{
        position: ${props => props.menuOpen ? "relative" : "Inicial"} ; ;
        //visibility: hidden ;

        label{
            visibility: hidden ;
        }
    }

    :hover{
        p{
            display: flex ;
        }
    }
`;

export const TooltipItem = styled.p`
    background-color: #1E1E1E ;
    color: white ;
    padding: 5px 10px ;
    display: none ;
    position: absolute ;
    z-index: 1 ;
    font-size: 9px ;
    top: ${props => props.menuOpen ? "0px" : "5px"}  ; ;
    left:  ${props => props.menuOpen ? "10px" : "55px"}  ; 
    border-radius:  8px;
    align-items: center ;
    width: max-content ;
    justify-content: center ;
    height: max-content;
    align-items: center ;


    :after{
        content: '';
        position: absolute;
        //left: 100%;
        left: -8px;
        border-top: 8px solid #1E1E1E ;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        transform: rotate(90deg);
    }
`



export const ItemMenuChaveEmpreender = styled.div`
    
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    //cursor: pointer;
    flex-direction: column;

    span{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        align-items: center;

        label{
            margin-left: 5px;
            font-size: 14px;
            cursor: pointer;
            color: ${props => props.selectedMenu ? "#20D489" : "#5e6278"};
            
        }
    }

    span:nth-child(2){

        cursor: pointer;
        label{
            font-size: 14px;
            
        }
    }

    span:nth-child(1){

        cursor: pointer;
        label{
            font-size: 14px;
            color: #20D489;
        }
    }

    
`;