import styled from "styled-components";

export const Container = styled.div`
    background-color: #f1f1f1 ;
    height: 350px ;
    //width: 60% ;
    border-radius: 10px ;
    display: flex ;
    //border: 2px solid gray ;
    //padding: 50px ;
    flex: 1 ;
    animation: Skeleton 1s ease infinite alternate;

    @media screen and (max-width: 1200px) {
        width: 100% ;
    }

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const BoxLeft = styled.div`
    //background-color: violet ;
    height: 100% ;
    max-width: 400px;
    width: 100% ;
    display:flex ;
    flex-direction: column ;
    padding: 50px ;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

`

export const BoxRight = styled.div`
    //background-color: purple ;
    height: 100% ;
    display: flex ;
    flex: 1;
    align-items: center ;
    justify-content: end ;

    @media screen and (max-width: 768px) {
        display: none ;
    }

    video{
        height: 350px;
        clip-path: inset(0.1px 0.1px)
    }
`

export const Title = styled.label`
    display: flex ;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px ;
    background-color: #dcdcdc;
    height: 20px ;
    width: 200px ;
    border-radius: 5px ;

`

export const SubTitle = styled.label`
    display: flex ;
    font-size: 12px;
    margin-bottom: 50px ;
    text-align: justify ;
    //background-color: yellow ;
    flex: 1 ;
    background-color: #dcdcdc;
    border-radius: 5px ;
    margin-top: 20px ;
`

export const Button = styled.button`
    display: flex;
    height: 50px ;
    width: 150px ;
    background-color: #dcdcdc; ; //#FBF9F6 ;
    align-items: center ;
    justify-content: center;
    border-radius: 5px ;
    border: none ;
    cursor: pointer;
    color: #1C1C1C ;
`