import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.background} ;
    display: flex;
    height: 250px ;
    //width: 100% ;
    width: 48%;
    flex-direction: row ;
    padding: 10px ;
    margin-bottom: 5px;
    //max-width: 600px !important;

    min-width: 300px;
    border: ${props => `${props.borderSize} solid ${props.borderColor}`}; 
    border-radius: ${props => props.borderRadius}  ;

    @media(max-width: 450px) {
        height: auto;
        flex-direction: column ;
        
    }

    @media(max-width: 1200px) {
        width: 100% ;
    }

    :hover{
        border: ${props => "1px solid " + props.colorBorder};
        cursor: pointer;
    }
`
export const SectionLeft = styled.div`
    display: flex ;
    background-color: white ;
    border-radius: 10px ;
    padding: 0px 5px ;

    @media(max-width: 450px) {
        justify-content: center ;
        //margin-top: 10px ;
    }

    img{
        width: 150px ;
        height: 100% ;
        object-fit: contain;
        cursor: pointer;
    }
`
export const SectionRigth = styled.div`
    display: flex ;
    //background-color: red ;
    flex-direction:  column;
    margin-left: 10px ;
    width: 100% ;

`
export const Title = styled.div`
    display: flex ;
    //background-color: red ;
    padding-top: 10px;
    label{
        font-size: 12px;
        font-weight: bold;
        color: ${props => props.fontColor};
        display: flex ;
        //background-color: red;
        align-items: center ;
        span{
            font-size: 9px ;
            margin-left: 5px ;
            font-weight: initial;
            //background-color: yellow;
            display: flex ;
            align-items: center ;
            margin-top: 1px ;
        }
    }
`
export const Description = styled.div`
    display: flex ;
    //background-color: red ;
    flex: 1 ;
    padding-top: 10px;
    color: ${props => props.fontColor};
    width: 100% ;

    label{
        font-size: 12px;
    }
`

export const Stars = styled.div`
    display: flex ;
    //background-color: red ;
    justify-content:  center;
    margin-top: 5px ;
    min-width: 100% !important ;

    justify-content: ${props => props.position == "default" ? "center" : props.position};
`


export const BadgeCheck = styled.div`
  position: relative;  
  display: flex ;
  //background-color: yellow ;

    svg{
      cursor: initial ;
    }

    :hover{

      label{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
      }
       
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        bottom: 30px;
        right: 0px;
        left: 32px;
        visibility: hidden;
        transform: translateX(-100%);
        /* display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        
        font-size: 10px;
        color: white; */
        
        font-size: 10px;
        color: white;
        font-family: ${props => props.theme.font} !important;

        :after{
       
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        } 
    }
`