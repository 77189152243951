import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.background} ;
    display: flex;
    height: 200px ;
    //width: 100% ; 
    width: 48%;
    flex-direction: column ;
    padding: 20px ;
    margin-bottom: 5px;
    //max-width: 600px !important;
    border: ${props => `${props.borderSize} solid ${props.borderColor}`}; 
    border-radius: ${props => props.borderRadius}  ;
    gap: 10px;

    :hover{
        border: ${props => "1px solid " + props.colorBorder};
        cursor: pointer;
    }

    @media(max-width: 1200px) {
        width: 100% ;
    }
`

export const Question = styled.div`
    //background-color: yellow;
    display: flex ;
    
    svg{
        margin-right: 10px ;
    }

    label{
        //background-color: red ;
        display: flex ;
        flex: 1;
        color: ${props => props.fontColor};
        font-weight: 600;
        font-size: 14px ;
    }
`

export const Answer = styled.div`
    //background-color:  yellow;
    display: flex ;
    flex: 1;

    svg{
        margin-right: 10px ;
    }

    label{
        //background-color: yellow ;
        display: flex ;
        flex: 1;
        color: ${props => props.fontColor};
        font-size: 12px ;
    }
    
`

export const Infor = styled.label`
    //background-color: purple ;
    font-size: 12px ;
    margin: 5px 0px ;
    color: ${props => props.fontColor};
    font-weight: 600;
    //display: flex ;

    span{
        font-size: 9px;
        //background-color: red;
        margin-left: 10px ;
        //display: flex ;
        //align-items: center ;
    }
`