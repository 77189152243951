import styled from "styled-components";

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 25px;
    border-radius: 5px;
    @media screen and (max-width: 500px) {
        width: 300px;
    }
    //background-color: purple ;
`;

export const Box = styled.form`
    display: flex ;
    flex-direction: column ;
    gap: 20px;

    @media screen and (min-width: 768px) {
        flex-direction: row ;
        
    }

    iframe{
        width: 100% ;
        height: 100%;
        //margin-right: 10px ;

        @media screen and (min-width: 768px) {
            width: 200px ;
        }
    }

    span{
        display: flex ;
        flex-direction: column ;
        //background-color: violet ;
        margin-top: -15px ;
        gap: 35px;

        @media screen and (min-width: 768px) {
           flex: 1 ;
           //margin-left: 0px ;
           justify-content: space-between ;
        }
    }
`;

export const Code = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    justify-content: space-between ;
    position: relative;

    label{
        background-color: #F5F8FA;
        padding: 10px 10px ;
        font-size: 12px;
        flex: 1;
    }

    svg{
        position: absolute;
        right: 0px ;
        top: 0px ;
        cursor: pointer;
    }
`;

export const Item = styled.label`
    //display: flex;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: justify;
`;

export const ItemTitle = styled.p`
    //display: flex;
    //font-size: 12px;
    margin-bottom: 10px;
`;


export const ButtonCustom = styled.span`
    display: flex;
    background-color: #ffcc00;
    outline: none;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: #5e0a07;
    font-weight: 600;
    cursor: pointer;

    @media screen and (min-width: 768px) {
        max-height: 50px !important;
        align-items: center ;
        //padding-top: 10px ;
    }

    :hover{
        opacity: 0.7;
    }
`;

export const Close = styled.span`
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 25px;
    cursor: pointer;
    z-index: 9999;
`;

export const BoxIfrane = styled.span`
    display: flex;
    margin-bottom: 10px ;

    iframe{
        width: 100% ;
        height: 100% ;

        
        @media screen and (min-width: 500px) {
            min-height: 250px ;
        }
    }
`;

export const Title = styled.div`
    display: flex;
    position: relative;
    color: #5e6278;
    margin-bottom: 10px;
`;

export const InputCustomShopify = styled.input`
    height: 35px;
    padding: 0px 10px;
    margin-bottom: 10px;
    border: none;
    outline: none;
    background-color: #F5F8FA;
    border-radius: 5px;
    font-size: 12px ;
`;

export const OptionsLabel = styled.div`
    display: flex ;
    background-color: #F5F8FA ;
    gap: 8px;
    flex-wrap: wrap ;
    padding: 8px;
    border-radius: 4px;

    label{
        display: flex ;
        background-color: #f1f1f1 ;
        padding: 6px 10px;
        border-radius: 4px ;
        font-size: 12px ;
    }
`
