import styled from "styled-components";

export const Container = styled.div`
    background-color: #f1f1f1 ;
    height: 200px ;
    border-radius: 10px ;
    display: flex ;
    flex-direction: column ;
    padding: 20px ;
    justify-content: space-between ;
    animation: Skeleton 1s ease infinite alternate;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const Title = styled.label`
    font-size: 18px;
    background-color: #dcdcdc;
    height: 20px ;
    border-radius:  5px;
    width: 150px ;
`

export const SubTitle = styled.label`
    font-size: 12px;
    margin-top: -10px ;
    background-color: #dcdcdc;
    height: 50px ;
    border-radius:  5px;
`

export const Search = styled.div`
    //background-color: yellow;
    height: 40px ;
    display: flex ;
    justify-content: space-between ;

    button{
        width: 40px ;
        height: 40px ;
        margin-left: 20px ;
        background-color: #dcdcdc ; //#FBF9F6 ;
        border: none ;
        border-radius: 5px ;
        cursor: pointer;
        display: flex ;
        justify-content: center ;
        align-items: center ;
    }

    span{
        display: flex ;
        width: 100px ;
        flex: 1 ;
        background-color: #dcdcdc ;
        border: none ;
        outline: none ;
        border-radius: 5px ;
        padding: 0px 5px ;
    }

`