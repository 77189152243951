import { Container, Search, SubTitle, Title } from "./styled"
import { VscSearch } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { getTranslation } from "../../../../translations";

const Help = () => {

  const [queryHelp, setQueryHelp] = useState("")
  const history = useHistory()
  const dispatch = useDispatch()

  const actionHelp = () => {
    dispatch({ type: "SET_QUERY_HELP", payload: queryHelp })
    history.push("/ajuda")
  }

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  return (
    <Container>
      <Title>{translation.dashboard.help.pesquisarDuvida}</Title>
      <SubTitle>{translation.dashboard.help.campoBusca}</SubTitle>
      <Search>
        <input placeholder={`${translation.dashboard.help.pesquisar} ...`} type={"text"} value={queryHelp} onChange={(e) => { setQueryHelp(e.target.value) }} />
        <button onClick={() => { actionHelp() }}>
          <VscSearch size={15} color={"#1C1C1C"} />
        </button>
      </Search>
    </Container>
  )
}

export default Help