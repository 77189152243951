import { Card, Price } from './styled'
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import Button from '../../../../components/Button/index'
import { getTranslation } from '../../../../translations';

const CardPlano = ({ name, value, idPlan, currentPlan, features }) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    //console.log("currentPlan", currentPlan)

    const linkPLus = () => {
        //console.log("linkplus", window)
        window?.empreenderPlusApiMethods?.verifyIfLoggedinBeforeRedirect(process.env.REACT_APP_EPLUS_DOMAIN + `/planos?app=LILY&p=${Buffer(`${idPlan}`).toString('base64')}&chave=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`)
    
    }

    return (
        <Card>
            <h3>{name}</h3>
            <Price>
                <span>R$ {value}</span>
                <small> / {translation.assinatura.cardPlano.mes}</small>
            </Price>
            <ul>

                {
                    features.map((funcionalidade, index) => {
                        if (!funcionalidade['ativo']) return '';

                        let textoFormatado = funcionalidade.nome;
                        let temAcesso = false;

                        if (idPlan in funcionalidade.planos) {
                            temAcesso = true;

                            let dadosSubstituir = funcionalidade.planos[idPlan];

                            Object.entries(dadosSubstituir).forEach(values => {
                                let regex = new RegExp(values[0].replace('[', '\\[').replace(']', '\\]'), 'g');
                                textoFormatado = textoFormatado.replace(regex, values[1]);
                            });

                        } else {
                            let regex = new RegExp(`\\[[^\\]]+\\]`, 'g');
                            textoFormatado = textoFormatado.replace(regex, '-');
                        }

                        return <li key={index}>{temAcesso ? <AiOutlineCheck /> : <AiOutlineClose />} {textoFormatado}</li>
                    })
                }

            </ul>
            {process.env.REACT_APP_HABILITAR_PLUS
                //&& currentPlan['id'] != idPlan
                &&
                <Button type="button" width="100%" marginTop="20px" background="#ffc400" color="#5e6278">
                    <a rel="noreferrer" onClick={() => { linkPLus() }} style={{ color: '#651006' }}>{translation.assinatura.cardPlano.verPlano}</a>
                </Button>
                // :
                // <Button type="button" width="100%" marginTop="20px" background="#FEE79E" color="#5e6278" style={{ pointerEvents: 'none' }}>
                //     {currentPlan['id'] != idPlan ? translation.assinatura.cardPlano.trocarPlano : translation.assinatura.cardPlano.planoAtual}
                // </Button>
            }
        </Card>
    );
}

export default CardPlano