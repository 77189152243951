import ptBr from "./ptBr.json";
import es from "./es.json";

export const getTranslation = (language) => {
    //console.log("teste language", language)
    let languages = {}
    languages.pt_br = ptBr
    languages.es = es

    if (typeof language != "undefined") {
        language = language.replace("-", "_")
    }else{
        language = "pt_br"
    }


    return languages[language]
}

