import React from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../translations'
import { Container, Contents, Infor, Item, Legends, Title } from './styled'

const TotalQuestions = () => {

    const dashboard = useSelector(state => state.reducerDashboard)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)


    //console.log("red %", `${Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100)}%`)
    //console.log("Yellow %", `${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100)}%`)
    //console.log("Green %", `${Math.round(dashboard.data?.answeredAsk / dashboard.data?.totalAsk * 100)}%`)
    //console.log("Blue %", `${Math.round(dashboard.data?.hiddenAsk / dashboard.data?.totalAsk * 100)}%`)

    // console.log(
    //     "FUll",
    //     `conic-gradient(
    //          #F13F6C 0% ${Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100)}%,
    //          #f0ad4e 0% ${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100)}%, 
    //          #20D489 0% ${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.answeredAsk / dashboard.data?.totalAsk * 100)}%, 
    //          #0275d8 0% ${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.answeredAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.hiddenAsk / dashboard.data?.totalAsk * 100)}%)
    //          `)


    return (
        <Container>
            <Title>{translation.dashboard.totalQuestion.perguntas}</Title>
            <Contents>
                <Infor
                    red={`${Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100)}%`}
                    yellow={`${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100)}%`}
                    green={`${Math.round(dashboard.data?.pendingAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.newAsk / dashboard.data?.totalAsk * 100) + Math.round(dashboard.data?.answeredAsk / dashboard.data?.totalAsk * 100)}%`}
                >
                    <span>
                        <label><strong>{dashboard.data?.totalAsk}</strong></label>
                    </span>
                </Infor>
                <Legends>
                    <Item backgroundLegend={"#F13F6C"}>
                        <span />
                        <label>{translation.dashboard.totalQuestion.novos} <strong>{dashboard.data?.newAsk}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#f0ad4e"}>
                        <span />
                        <label>{translation.dashboard.totalQuestion.pendentes} <strong>{dashboard.data?.pendingAsk}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#20D489"} >
                        <span />
                        <label>{translation.dashboard.totalQuestion.respondidos} <strong>{dashboard.data?.answeredAsk}</strong></label>
                    </Item>
                    <Item backgroundLegend={"#0275d8"}>
                        <span />
                        <label>{translation.dashboard.totalQuestion.escondidos} <strong>{dashboard.data?.hiddenAsk}</strong></label>
                    </Item>
                </Legends>
            </Contents>
        </Container>
    )
}

export default TotalQuestions