import Page from "../../components/Page";
import { AiFillMail } from "react-icons/ai";
import InputCustom from "../../components/InputCustom";
import { FormRegister, Actions } from "./styled";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import Btn from "../../components/Btn";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../components/ErrorInput";
import InputMask from "react-input-mask";
import { useEffect } from "react";
import { useState } from "react";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import PhoneInput from 'react-phone-input-2';
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../../../translations";

const Whatsapp = (props) => (<InputMask {...props} mask="(99) 9.9999-9999" maskChar="" />)

const Register = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("")
    const [phoneError, setPhoneErro] = useState(false)

    const statusRegister = useSelector(state => state.reducerRegister)
    const language = useSelector(state => state.reducerControlLanguage)

    const [id, setId] = useState(null)

    let translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");

    useEffect(() => {
        //console.log("teste language localstorage", language, localStorage.getItem("depoimentos@pais"))
        translation = getTranslation(localStorage.getItem("depoimentos@pais") == "BR" ? "pt-br" : "es");
      }, [language])
    
    useEffect(() => {
        if (!(statusRegister.loading === false && statusRegister.error === false && statusRegister.data.length === 0)) {
            if (statusRegister.loading === true) {
                setId(toast.loading(translation.login.register.aguarde, { toastId: 0 }))
            } else {
                if (statusRegister.error === true) {
                    //console.log("statusRegister: ",statusRegister)
                    toast.update(id, { render: statusRegister.data.description, type: "error", isLoading: false, autoClose: 5000 });
                } else if (statusRegister.error === false) {
                    toast.update(id, { render: translation.login.register.criadoSucesso, type: "success", isLoading: false, autoClose: 2000 });
                    formik.resetForm()
                    history.push("/")
                }
            }
        }
    }, [statusRegister])

    useEffect(() => {
        if (localStorage.getItem("depoimentos@login")) {
            history.push("/")
        }
    }, [])

    const formik = useFormik({
        initialValues: { name: '', email: '', whatsapp: '', password: '', confirmationPassword: '' },
        validationSchema: Yup.object({
            name: Yup.string().required(translation.login.register.formik.name.validacao1).max(200, translation.login.register.formik.name.validacao2).min(3, translation.login.register.formik.name.validacao3),
            email: Yup.string().required(translation.login.register.formik.email.validacao1).email(translation.login.register.formik.email.validacao2).max(200, translation.login.register.formik.email.validacao3).min(3, translation.login.register.formik.email.validacao4),
            //whatsapp: Yup.string().required("Preencher o campo whatsapp.").min(13, "Whatsapp invalido1.").max(13, "Whatsapp invalido2."),
            password: Yup.string().required(translation.login.register.formik.password.validacao1).min(8, translation.login.register.formik.password.validacao2).max(200, translation.login.register.formik.password.validacao3),
            confirmationPassword: Yup.string().required(translation.login.register.formik.confirmationPassword.validacao1).oneOf([Yup.ref('password'), null], translation.login.register.formik.confirmationPassword.validacao2),
        }),
        onSubmit: async (values) => {
            values.country = localStorage.getItem("depoimentos@pais")
            if (phone.length > 5) {
                dispatch({ type: "SAGA_REGISTER_LOGIN", payload: { ...values, whatsapp: phone } })
            } else {
                setPhoneErro(true)
            }

        },
    });

    useEffect(() => {
        setPhoneErro(false)
    }, [phone])



    return (
        <Page>
            <FormRegister onSubmit={formik.handleSubmit}>
                <InputCustom>
                    <span>
                        <FaUserAlt size={20} color={"gray"} />
                    </span>
                    <input name={"name"} value={formik.values.name} onChange={formik.handleChange} type={"text"} placeholder={translation.login.register.nome} />
                </InputCustom>
                {formik.errors.name && formik.touched.name && <ErrorMessage>{formik.errors.name}</ErrorMessage>}

                <InputCustom>
                    <span>
                        <AiFillMail size={20} color={"gray"} />
                    </span>
                    <input name={"email"} value={formik.values.email} onChange={formik.handleChange} type={"text"} placeholder={translation.login.register.email} />
                </InputCustom>
                {formik.errors.email && formik.touched.email && <ErrorMessage>{formik.errors.email}</ErrorMessage>}

                {/* <InputCustom>
                    <span>
                        <RiWhatsappFill size={20} color={"gray"} />
                    </span>
                    <Whatsapp key="editor1" name="whatsapp" value={formik.values.whatsapp} onChange={formik.handleChange} id="whatsapp" type="text" placeholder="Whatsapp" />
                </InputCustom>
                {formik.errors.whatsapp && formik.touched.whatsapp && <ErrorMessage>{formik.errors.whatsapp}</ErrorMessage>} */}

                {/* <InputCustom> */}
                <span style={{ position: "relative" }}>
                    <PhoneInput
                        countryCodeEditable={false}
                        dropdownStyle={{ width: "500px", }}
                        containerStyle={{ marginBottom: "20px" }}
                        containerClass="teste"
                        inputStyle={{
                            borderRadius: "20px",
                            width: "100%",
                            backgroundColor: '#F7F7F7',
                            border: "none",
                            outline: "none",
                            height: "45px",
                            border: "1px solid #DCDBDA",
                            paddingLeft: "50px",

                        }}
                        buttonStyle={{
                            backgroundColor: "transparent",
                            border: "none",
                            marginLeft: "10px",
                        }}
                        country={'br'}
                        value={phone}
                        onChange={phoneCustom => setPhone(phoneCustom)}
                    />
                    <span style={{ position: "absolute", top: 10, right: 10 }}>
                        <RiWhatsappFill size={20} color={"gray"} />
                    </span>
                </span>
                {phoneError && <ErrorMessage>{translation.login.register.whatsapp}</ErrorMessage>}


                {/* </InputCustom> */}


                <InputCustom>
                    <span>
                        <FaLock size={20} color={"gray"} />
                    </span>
                    <input name={"password"} value={formik.values.password} onChange={formik.handleChange} type={"password"} placeholder={translation.login.register.senha} />
                </InputCustom>
                {formik.errors.password && formik.touched.password && <ErrorMessage>{formik.errors.password}</ErrorMessage>}

                <InputCustom>
                    <span>
                        <FaLock size={20} color={"gray"} />
                    </span>
                    <input name={"confirmationPassword"} value={formik.values.confirmationPassword} onChange={formik.handleChange} type={"password"} placeholder={translation.login.register.confirmarSenha} />
                </InputCustom>
                {formik.errors.confirmationPassword && formik.touched.confirmationPassword && <ErrorMessage>{formik.errors.confirmationPassword}</ErrorMessage>}

                <Actions>
                    <Btn type="submit" label={translation.login.register.experimenteGratis}
                        onClick={() => { if (formik.dirty === false) toast.warning(translation.login.register.preenchaDados, { toastId: 0 }) }}
                    />
                    <label onClick={() => { history.push("/login") }}>{translation.login.register.possuiConta}</label>
                </Actions>
            </FormRegister>
            {isVisibleScript(false)}
        </Page>
    )
}

export default Register
