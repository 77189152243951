import styled from "styled-components";

export const Container = styled.div`
    background-color: ${props => props.theme.colors.layout.secondary};
    width: 100%;
    //min-height: 100%; 

    padding: ${props => props.menuOpen ? "30px 30px 30px 230px" : props.padding == undefined ? "30px 30px 30px 102px" : "0px 0px 0px 72px" };
//padding-left: 280px ;
//background-color: red;
transition: 0.5s ease-in;
height: 100%;


@media screen and(max-width: 360px) {
    padding: 5px 5px 5px 5px!important;
}

@media screen and(max-width: 768px) {
    padding-left: 30px;
}


`;