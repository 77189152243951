import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background-color: white;
    width: 500px;
    height: 200px;
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 50px;
    border-radius: 10px;
    cursor: pointer;
    

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

`;

export const SectionInfor = styled.div`
    display: flex;
    width: 320px;
    height: 100%;
    background-color: ${props => props.background};
    flex-direction: column !important;
    padding: 20px;
    border-radius: 10px 0px 0px 10px;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
`;

export const SectionIcon = styled.div`
    display: flex;
    width: 120px;
    height: 100%;
    background-color: ${props => props.background};
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
`;

export const Description = styled.label`
    font-size: 14px;
    color: ${props => props.colorFont};
    font-weight: bold;
    cursor: pointer;

`;

export const Infor = styled.label`
    font-size: 26px;
    color: Gray;
    font-weight: bold;
    margin: 20px 0px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }

`;

export const Btn = styled.div`
    display: flex;
    background-color: purple;
    width: 100px;
    height: 25px;
`;