import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import ReactModal from 'react-modal'
import Reviews from '../../../reviews/redux/reducers/reducerReviews';
import { Actions, ButtomCustom, Container, Contents, Form, InputCustom, Title } from './styled';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
    }
};



const ModalXpatch = (props) => {

    const [reviews, setReviews] = useState(props.item.xpath_reviews)
    const [reviewsMobile, setReviewsMobile] = useState(props.item.xpath_reviews_mobile)
    const [stars, setStars] = useState(props.item.xpath_stars)
    const [starsMobile, setStarsMobile] = useState(props.item.xpath_stars_mobile)

    return (
        <ReactModal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            <Container>
                <Title>
                    <label>{`${props.item.name} [${props.item.chave_empreender}]`}</label>
                </Title>
                <Form>
                    <span>
                        <label>Avaliações</label>
                        <input
                            type={"text"}
                            value={reviews}
                            onChange={(e) => { setReviews(e.target.value) }}
                            placeholder={"Xpath de evaliações"}
                        />
                    </span>

                    <span>
                        <label>Avaliações mobile</label>
                        <input
                            type={"text"}
                            value={reviewsMobile}
                            onChange={(e) => { setReviewsMobile(e.target.value) }}
                            placeholder={"Xpath de evaliações mobile"}
                        />
                    </span>

                    <span>
                        <label>Estrelas</label>
                        <input
                            type={"text"}
                            value={stars}
                            onChange={(e) => { setStars(e.target.value) }}
                            placeholder={"Xpath de estrelas"}
                        />
                    </span>

                    <span>
                        <label>Estrelas mobile</label>
                        <input
                            type={"text"}
                            value={starsMobile}
                            onChange={(e) => { setStarsMobile(e.target.value) }}
                            placeholder={"Xpath de estrelas mobile"}
                        />
                    </span>


                </Form>
                <Actions>
                    <ButtomCustom onClick={() => { props.setVisibleModal(false) }} backgroundColor={"#F1416C"} >
                        <label>Cancelar</label>
                    </ButtomCustom>
                    <ButtomCustom onClick={() => { props.saveXpatch(reviews, reviewsMobile, stars, starsMobile) }} backgroundColor={"#19B674"}>
                        <label>salvar</label>
                    </ButtomCustom>
                </Actions>
            </Container>
        </ReactModal>
    )
}

export default ModalXpatch