import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    gap: 0px;
    background-color: white ;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 0px 0px 0px 2px;
    margin: 10px 60px 40px 60px;
    //background-color: red ;

    @media(max-width: 768px) {
        //background-color: red ;
        flex-direction: column ;
        width: calc(100% - 0px) ;
        margin: 0px ;
        gap: 10px;
    }

    @media(max-width: 1100px) {
        flex-direction: column;
    }
`

export const BoxOptions = styled.div`
    display: flex ;
    //background-color: red ;
    flex-direction: column ;
`

export const BoxContentStep = styled.div`
    display: flex ;
    //background-color: purple ;
    flex: 1;
    padding: 20px;
    flex-direction: column;
    height: 450px;
    overflow: auto ;
`

export const StepCircle = styled.span`
    display: flex ;
    //background-color: #17238F;
    justify-content: left ;
    label{
        display: flex ;
        font-size: 14px ;
        color: gray ;
        width: 100%;
        height: 100% ;
        width: 30px;
        height: 30px;
        border-radius: 50% ;
        justify-content: center ;
        align-items: center ;
        border: 1px solid gray ;
    }
`

export const ItemOption = styled.div`
    display: flex ;
    background-color: white;
    width: 300px ;
    padding: 20px ;
    height: 150px ;
    align-items: center ;
    border-bottom: 1px solid #E5E7EB;
    border-left: ${props => props.active ? "5px solid #54D18C" : "none"};
    cursor: pointer;
    border-right: ${props => !props.active ? "1px solid #E5E7EB" : "none"}; 

    @media(max-width: 1100px) {
        width: 100% ;
    }

    :last-child{
        //background-color: red ;
        border-bottom: none;

        @media(max-width: 1100px) {
            border-bottom: 1px solid #E5E7EB;
        }
    }

    :hover{
        background-color: #f1f1f1 ;
    }

    label{
        //margin-left:10px ;
        cursor: pointer;
    }

`

export const Title = styled.div`
    font-size: 20px ;
    font-weight: 700;

    span{

position: relative ;
:hover{

    label{
        visibility: visible ;
    }
}

label{
    display: flex;
    position: absolute;
    background: #292929cc;
    visibility: hidden;
    border-radius: 5px;
    //max-width: 300px !important; 
    width: max-content;
    padding: 10px 10px;
    transform: translateX(-100%);
    left: 0;
    margin-top: -85px;
    margin-left: 38px;
    color: white ;
    font-size: 11px !important;

    :after{
        content: '';
        position: absolute;
        top: 100%;
        right: 10px;
        border-top: 8px solid #292929cc;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
}
}
`

export const Description = styled.div`
    font-size: 14px ;
    margin-top: 10px;
`
export const SectionCardsIntegration = styled.div`
    display: flex ;
    gap: 20px;
    margin: 20px 0px ;
    flex-wrap: wrap;
    flex: 1 ;
`

export const BoxCupomContent = styled.div`
 //background-color: red ;
 display: flex ;
 flex: 1 ;
 align-items: center;
 justify-content: center ;

 label{
    background-color: #54D18C ;
    padding: 10px ;
    cursor: pointer;
    border-radius: 5px ;
    color: white ;
 }
`
export const Btn = styled.label`
    display: flex;
    background: transparent;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 8px;
    width: 170px ;
    //justify-content: center ;
    cursor: pointer;
    margin: 10px 0px;
    align-items: center ;
    gap: 8px;
`

export const TextHelp = styled.label`
    display: flex ;
    font-weight: bold ;
`

export const TextSupport = styled.label`
   display: block ;

    strong{
        margin-left: 5px;
        cursor: pointer;

        :hover{
            text-decoration: underline ;
        }
    }
`
export const SectionContents = styled.label`
    display: flex ;
    flex: 1 ;
    flex-direction: column ;
`

export const TitleSteps = styled.label`
    display: flex ;
    //background-color: yellow ;
    margin: 0px 60px 0px 60px ;
    font-size: 22px ;
    font-weight: bold ;
`

export const DescriptionSteps = styled.label`
    display: flex ;
    //background-color: red ;
    margin: 0px 60px ;
    font-size: 12px;
`