import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { GrFormClose } from "react-icons/gr";
import { AiFillTag, AiOutlineNumber } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom, ImgCustom } from "./styled";

import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalCode = (props) => {

    const dispatch = useDispatch()

    // const formik = useFormik({
    //     initialValues: { product_id: '', canonical_url: '', name: "", description: '', image_url: "" },
    //     validationSchema: Yup.object({
    //         canonical_url: Yup.string().required("Preencher o campo URL do produto."),
    //         name: Yup.string().required("Preencher o campo nome.").max(100, "Máximo de 50 caracteres.").min(3, "Minimo de 3 caracteres."),
    //         description: Yup.string().required("Preencher o campo descrição.").min(3, "Minimo de 3 caracteres.").max(100, "Máximo de 100 caracteres."),
    //         image_url: Yup.string().required("Preencher o campo URL da imagem."),
    //     }),
    //     onSubmit: (values) => {
    //         dispatch({ type: "SAGA_ADD_PRODUCT", payload: values })
    //     },
    // });

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const copy = () => {
        //navigator.clipboard.writeText(tag)
        //setCopyKey(text)
        toast.success(translation.products.modalPreview.codigoCopiado)
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <div>
                <ImgCustom src={props?.item?.image_url} />
            </div>
        </Modal>
    )
}

export default ModalCode
