import React from 'react'
import { BoxActions, BoxInfor, Container } from './styled'

const CardLoading = () => {
    return (
        <Container>
            <BoxInfor>
                {Array(3).fill(<div />)}
                <span>
                    {Array(Math.floor(Math.random() * 4) + 1).fill(<span />)}
                </span>
            </BoxInfor>
            <BoxActions>
                {Array(4).fill(<div />)}
            </BoxActions>
        </Container>
    )
}

export default CardLoading