import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 //@import url('https://fonts.googleapis.com/css?family=Poppins:400,600');
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none ;
  } 

    body{
      font-family: 'Poppins', sans-serif;
      color: ${props => props.theme.colors.font.default};
      background-color: #FBF9F6 ;
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
    }

    button {
      font-family: 'Poppins', sans-serif;
    }

    .spinner {
      animation: spin infinite 1s linear;

      /*You can increase or decrease the timer (5s) to 
      increase or decrease the speed of the spinner*/
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

`;

export default GlobalStyle;