import styled from 'styled-components'
import hero from '../../../../assets/imgs/lilly-payment-hero.svg'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    color: #eaeaea;
    border-radius: 0;
    margin-bottom: 0;
    padding: 2rem;
    height: auto;
    background-size: 35%;
    min-height: 330px;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 10px;

    background: white url(${hero}) no-repeat bottom -10vh right 0; 
    background-position: right; 
    background-size: 20%;

    

    @media(max-width: 1250px) {
        background: white url('') no-repeat bottom -10vh right 0; 
    }

    h1 {
        font-size: 2.5rem;
        font-weight: 600;
        color: ${props => props.theme.colors.font.default} !important;
    }
    p {
        font-size: 1rem;
        font-weight: 500 !important;
        color: #aeafb2;
    }
    a {
        color: #fff;
        background: #ffc107;
    }
    
`


export const BoxInforInitial = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px ;
    margin-bottom: 32px ;
    //background-color: red;

    @media screen and (max-width: 768px) {
        flex-direction: column ;
        gap: 20px;
        //background-color: red ;
    }
`;

export const BoxInforInitialLeft = styled.div`
    display: flex;
    //background-color: #808080;
    flex-direction: column ;
    flex: 1;

    //background-color: yellow;

    label{

    }
    label:nth-child(1){
        font-weight: 600;
        font-size: 28px;
        color: #5E6278;
    }

    label:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #5E6278;
    }
`;

export const BoxInforInitialRight = styled.div`
    display: flex;
    
    max-width: 582px;
    align-items: center ;
    justify-content: center ;
    //background-color: purple ;

    @media screen and (max-width: 768px) {
        justify-content: left ;
    }
    
    label{
        background-color: #FFC400;
        display: flex ;
        padding: 8px ;
        border-radius: 4px ;
        font-size: 12px ;
        font-weight: 400;
        font-size: 14px;
        color: #2b2b2b;
        cursor: pointer;
    }

`;


export const InforPlus = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #212529;
    height: 180px;
    border-radius: 8px;
    padding: 32px 64px;
    margin-bottom: 30   px;
`

export const InforPlusText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 300;
    color: #d7d7d7;
`

export const InforPlusBtn = styled.div`
    display: flex;
    text-decoration: none;
    outline: 0;
    transition: all .3s ease;

    box-shadow: inset 0 0 0.5em 0 rgba(0, 224, 209, 1), 0 0 0.5em 0 rgba(0, 224, 209, 1) !important;
    color: rgba(0, 224, 209, 1) !important;
    border: 0.125em solid rgba(0, 224, 209, 1) !important;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor !important;

    :before {
        top: 120% !important;
		left: 0 !important;
		width: 100% !important;
		height: 100% !important;
		pointer-events: none !important;
		content: "" !important;
		position: absolute !important;
		transform: perspective(1em) rotateX(40deg) scale(1, 0.35) !important;
		filter: blur(1em) !important;
		opacity: 0.7 !important;

        background: hsla(176, 100%, 44%, 1) !important;
    }

    label{
        transition: all .3s ease;
        cursor: pointer; 
    }
`
