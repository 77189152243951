import styled, { keyframes } from "styled-components";

export const Container = styled.div`
    background-color: #f1f1f1 ;
    //height: 115px ;
    display: flex ;
    //align-items: center;
    padding: 20px ;
    animation: Skeleton 1s ease infinite alternate;
    border-radius: 10px ;
    margin-left: 0px;

    @keyframes Skeleton{
    to{
        opacity: 0.5;
    }
}
`



export const BoxInfor = styled.div`
    //background-color: green ;
    display: flex ;
    flex: 1;
    margin-left: 20px;
    flex-direction: column ;
    gap: 10px;
    

    span{
        //background-color: red ;
        display: flex ;
        gap: 20px;

        span{
            background-color: #dcdcdc;
            border-radius: 5px;
            width: 50px ;
            height: 50px ;
        }
    }

    div:nth-child(1){
        width: 300px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(2){
        width: 250px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }

    div:nth-child(3){
        width: 100px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }
`

export const BoxActions = styled.div`
    //background-color: currentColor ;
    display: flex ;
    gap: 20px;
    align-items: center ;


    div{
        width: 30px;
        height: 20px;
        background-color: #dcdcdc;
        border-radius: 5px ;
    }
    
`