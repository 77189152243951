import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    background-color: transparent;
    width: 500px;
    border-radius: 5px;
    //padding: 30px;
    margin-bottom: 50px;
    border-radius: 10px;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

`;

export const DataInfor = styled.table`


    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    border-collapse: collapse;
    //margin: 25px 0;
    font-size: 12px;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    //margin: 0px 25px 25px 25px;
   
    thead{
        height: 50px;
        
        tr{
            background-color: #F1BC00; // #131628
            color:  white;
            text-align: center;            
            th, td{
                padding: 12px 15px;
            }
        }
    }

    tBody{
        tr{
            border-bottom: 1px solid #dddddd;
            height: 40px;
            
            th{
                
                a{
                    text-decoration: none;
                    :hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        tr:nth-of-type(even){
            background-color: #FFF8DD; //#f3f3f3;
            color: #F1BC00;
        }
        tr:last-of-type{
            border-bottom: 2px solid #EFF2F5;
        }
        tr.active-row{
            font-weight: bold;
            //color: #009879;
        }
    }

`;
