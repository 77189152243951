import styled from "styled-components";

export const Container = styled.div`
    //background-color: green ;
    display: flex ;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 30px;

`
export const BoxOptions = styled.div`
    width: 170px ;
    display: flex ;
    background-color: #F1F1F1 ;
    flex-direction: column ;
    gap: 20px;
    padding: 20px ;

    animation: Skeleton 1s ease infinite alternate;
    border-radius: 10px ;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const BoxPreview = styled.div`
    display: flex ;
    flex: 1;
    background-color: #DCDCDC;
    margin-left: 20px ;
    border-radius: 10px ;
    height: 500px;
    //width: 100px ;
    animation: Skeleton 1s ease infinite alternate;

    @keyframes Skeleton{
        to{
            opacity: 0.5;
        }
    }
`

export const Option = styled.div`
    display: 150px ;
    //height: 80px ;
    //background-color: red ;
    
    flex-direction: column ;

    span{
        background-color: #DCDCDC ;
        height: 15px ;
        display: flex ;
        margin-bottom: 10px ;
        width: 100px;
        border-radius: 5px ;
    }

    div{
        background-color: #DCDCDC;
        height: 40px ;
        display: flex ;
        border-radius: 5px ;
    }
`