import styled from 'styled-components'
import {darken, lighten} from 'polished'


export const Container = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 500;
    padding: ${props => props.padding};
    font-size: 0.9rem;
    background: ${props => lighten(0.4, props.background ?? '#ffc700')};
    border-radius: 5px;
    color: ${props => darken(0.2, props.background ?? '#ffc700')};
    border: 1px dashed ${props => lighten(0.04, props.background ?? '#ffc700')};
    /* margin-bottom: ${props => props.marginBottom};
    margin-top: ${props => props.marginTop}; */
    margin: ${props => props.margin};
    align-items:  center !important;

    @media screen and (max-width: 768px) {
        //margin-left: 30px ;
    }

    span {
        svg {
            color: ${props => lighten(0.03, props.background ?? '#ffc700')};
        }
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    //background-color: purple ;
    align-items: center !important;
    justify-content: center ;
`