import styled from "styled-components";

export const Container = styled.div`
    background-color: white ;
    height: 115px ;
    width: 100% ;
    border-radius: 10px ;
    padding: 20px ;
    display: flex ;
    align-items: center ;

    @media screen and (max-width: 1200px) {
        margin-top: 35px;
    }

    label{
        margin-right: 20px ;
        font-size: 18px;
    }

    div{
        display: flex ;
        //background-color: yellow ;
        flex: 1;
        justify-content: space-between ;
        height: 100% ;
        align-items: center ;

        @media screen and (max-width: 1200px) {
            justify-content: space-evenly;
        }
        
        span{
            margin: 0px 5px ;
            border-radius: 50% ;
            display: flex;
            justify-content: center ;
            align-items: center ;
            width: 40px;
            height: 40px ;
            cursor: pointer;
            position: relative;

            :hover div{
                visibility: visible;
                opacity: 0.9;
                top: 30px;
                z-index: 999;
            }

            :nth-child(1){
                background-color: #ff0000 ;
            }
            :nth-child(2){
                background-color: #FF3685 ;
            }
        }
    }
`

export const Tooltip = styled.div`

        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 5px 10px;
        transform: translateX(-100%);
        left: 0;
        margin-top: -90px;
        margin-left: 35px;

        label{
            font-size: 12px;
            color: white;
        }
  

        :after{
            content: '';
            position: absolute;
            top: 100%;
            right: 10px;
            border-top: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    
`;