import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const SelectCustom = styled.select`
    outline: none;
    border-radius: 5px;
    height: 40px;
    padding: 0px 5px;
    cursor: pointer;
    border: none;
    background-color: ${props => props.theme.colors.layout.secondary};
    font-weight: 500;
    color: #5E6278;
    font-size: 14px ;
    min-width: 150px;
`;

export const Filters = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    flex-wrap: wrap;


    select{
        outline: none;
        border-radius: 5px;
        height: 40px;
        padding: 0px 10px;
        cursor: pointer;
        border: none;
        background-color: ${props => props.theme.colors.layout.secondary};
        color: #A1A5B6;
        min-width: 150px;
   }

   button{
       height: 40px;
       width: 100px;
       outline: none;
       border: none;
       background-color: ${props => props.theme.colors.layout.secondary} !important;
       border-radius: 5px;
       color: gray;
       cursor: pointer;
       border: 1px solid #d3d3d3;
   }
`;

export const SectionLeft = styled.div`
    display: flex;
    margin: 10px 0px;
`;

export const SectionRight = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
`;

export const Search = styled.div`
    //background-color: #FBF9F6;
    background-color: ${props => props.theme.colors.layout.secondary};
    display: flex;
    height: 40px;
    //border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    align-items: center;

    input{
        display: flex;
        height: 100%;
        width: 250px;
        outline: none;
        border: none;
        background-color: transparent;
    }
`;

export const Check = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    color: gray;
    span{
        display: flex;
        width: 20px;
        height: 20px;
        background-color: #FBF9F6;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        margin-right: 5px ;
        border-radius: 4px;
    }
`;

export const DropDown = styled.div`
    display: flex;
    background-color: orange;

    span{
        display: none;
        flex-direction: column;
    }
`;

export const Infor = styled.div`
    min-height: 50px;
    display: flex;
    align-items: center;


    label{
        margin-right: 10px; 
    }
    /* button{
        display: flex;
        height: 35px;
        background-color:  yellow;
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        color: black;
        border: 1px solid gray;
        width: 150px;

        div{
            background-color: #FBF9F6;
            width: 200px;
            position: absolute;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 9999;
            padding-left: 5px;
            border: 1px solid gray;
            border-radius: 5px;

            label{
                //background-color: peru;
                text-align: left;
                width: 100%;
                cursor: pointer;
                height: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: black;
                
                
                :hover{
                    opacity: 0.5;
                }
            }
        } 
    } */

    
`;

export const ListCard = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
`;

export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const ButtonAdd = styled.div`
    background-color: #19B674;
    display: flex;
    height: 35px;
    border-radius: 5px;
    justify-content: space-between;
    color: white;
    padding: 0px 5px;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: tomato;
        margin-left: -5px;
        cursor: pointer;
    }
    label{
        display: flex;
        align-items: center;
        cursor: pointer;
        //background-color: violet;
    }

    //align-items: center;
`;

export const Paginate = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 5px;
    align-items: center;

    span{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    label{
        margin: 0px 10px;
    }
`;


export const FormCustom = styled.form`
    //background-color: yellow;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    margin-top: 48px;

`;

export const InputCustom = styled.div`
    //background-color: red;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    
    label{
        font-size: 14px;
        color: #5E6278;
    }

    input{
        background-color: #FBF9F6;
        height: 40px;
        outline: none;
        border: none;
        padding: 0px 10px;
        border-radius: 5px;
        font-weight: 500;
        color: #5E6278;
        font-size: 14px ;
    }
`;


export const ButtonSave = styled.button`
    background-color: #19B674;
    width: 75px;
    height: 35px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
`;

export const ButtonDelete = styled.button`
    background-color: transparent;//;#F13F6C;
    width: 130px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #F13F6C;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #5E6278;
`;


export const ErrorMessage = styled.label`
    //background-color: yellow;
    display: flex;
    margin-top: -15px;
    margin-bottom: 5px;
    font-size: 12px;
    color: red;
`;


export const BoxInforInitial = styled.div`
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 16px ;
`;

export const BoxInforInitialLeft = styled.div`
    display: flex;
    //background-color: #808080;
    flex-direction: column ;
    flex: 1;

    label{

    }
    label:nth-child(1){
        font-weight: 600;
        font-size: 28px;
        color: #5E6278;
    }

    label:nth-child(2){
        font-weight: 400;
        font-size: 16px;
        color: #5E6278;
    }
`;

export const BoxInforInitialRight = styled.div`
    display: flex;
    
    width: 582px;
    align-items: center ;
    justify-content: center ;
    
    label{
        background-color: #FFC400;
        display: flex ;
        padding: 8px ;
        border-radius: 4px ;
        font-size: 12px ;
        font-weight: 400;
        font-size: 14px;
        color: #2b2b2b;
        cursor: pointer;
    }

`;

export const BoxDeleteAccount = styled.span`
    background-color: white ;
    display: flex ;
    flex-direction: column ;
    margin-top: 48px ;
    padding: 16px ;

    label{
        margin: 0px ;
        color: #5E6278;
    }

    
`