
import { Container } from "./styled";

const Btn = (props) => {

    return (
        <Container onClick={props.onClick} >
            {props.label}
        </Container>
    )
}

export default Btn
