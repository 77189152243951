import styled from "styled-components";

export const Container = styled.div`
  //background-color: ${props => props.theme.background_color_secondary};
  width: 325px;
  height: 569px;
  display: flex;
  margin: 5px 20px 60px 20px;
  flex-direction: column ;
  //padding: 15px ;
  border-radius: 10px ;  
  //background-color: green;
  /* @media(max-width: 800px) {
    width: 98% ;
  } */
  cursor:  ${props => props?.mediaType != 'tiktok'? 'pointer' : 'default' };
 
  &:hover {
    //border: ${props => "1px solid " + props.theme.primary_color};
    //cursor: pointer;
  }
  .info-container {
      width: 320px;
      height: 310px;
      background-color: bisque;
      position: absolute;    
      transition: height 2s;  
      padding-bottom: 5px;
      margin-left: -110px;
      margin-top: 10px;
      border-radius: 15px;
      z-index: -2; 
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.5s ease;
  }
`;

export const BoxMedia = styled.div`
  display: flex; 
  align-items: center ;
  justify-content: center ;
  width: 100% ; 
  height: 100% ;
  background-color: transparent ;
  border-radius: 10px ;
  position: relative;
  /* img{
    object-fit: cover ;
    width: 100% ;
    height: 100% ;
    max-width: 290px ;
    min-width: 290px ;
    max-height: 200px ;
    border-radius: 10px !important;
    border-radius: 50%;
  } */

`;

export const TitleName = styled.div`
  width: 100% ;
  height: 45px ;
  display: flex ;
  align-items: center ;
  //background-color: red;
  margin-top: 5px ;

  h4{
    text-transform: uppercase ;
    height: 100% ;
    display:flex ;
    align-items: center ;
    width: 100% ;
    font-family: ${props => props.theme.font} !important;
  }
`

export const BoxInfor = styled.div`
  width: auto ;
  height: auto ;
  display: flex ;
  align-items: center;
  justify-content: space-between;
  //background-color: yellow;
  margin-top: 10px ;
  margin-bottom: 10px ;
  font-family: ${props => props.theme.font} !important;
  position: absolute;
  bottom: 0;
  left: auto;
  right: auto;
  margin-bottom: -13px;
  span{
    //background-color: red;
    display: flex ;
    align-items: center ;
  }
`

export const BoxText = styled.div`
label {
  width: calc(100% - 30px) ;
  height: 205px ;
  display: flex ;
  overflow: hidden ;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  text-align: justify ;
  font-size: 14px ;
  font-family: ${props => props.theme.font} !important;
  padding: 5px 15px;
}
`

export const CardPlay = styled.div`
  width: calc(100% - 47px);
  height: 100% ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
  border: 1px solid #d3d3d3 ;
  background-color: white ;
  border-radius: 5px ;  
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  

  img {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    //object-fit: cover;
    border-radius: 5px;
    padding: 10px ;
    background-color: white;
  }
`

export const CardStar = styled.div`
  width: fit-content;
  height: fit-content ;
  display: flex ; 
  border: 1px solid #d3d3d3 ;
  margin: auto auto;
  background-color: ${props => props.backgroundColor} ;
  border-radius: 15px ;
  padding: 5px;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  bottom: -5px;
  left: auto;
  right: auto;
  margin-bottom: ${props => props.isClicked ? '300px': '-14px'}; ;
  z-index: 9999;
  transition: margin 0.5s ease;

  :hover {
    cursor: pointer;
  }
`

export const CardInfo = styled.div`
  width:  ${props => props.isClicked ? '260px': '0px'};
  height: ${props => props.isClicked ? '310px': '0px'};
  //background-color: white ;
  position: absolute;    
  transition: height 0.5s ease;
  padding-bottom: 5px;
  margin-left: -80px;
  margin-top: 10px;
  border-radius: 15px;
  z-index: -2;
  visibility: ${props => props.isClicked ? 'visible': 'hidden'};
  overflow: hidden;
  background-color: ${props => props.backgroundColor} ;
 

  .client-name-lily {
    display: flex;
    margin: 25px auto;
    width: auto;
    font-weight: 700;
    font-size: 16px;
    flex-direction: column;
    //background-color: white ;
    span {
      text-align: center;     
      margin: 5px;
      //background-color: white ;
    }
    h5 {
      text-align: center;
      font-weight: 400;
      color: gray;
      margin: 0;
      //background-color: white ;
    }
  }
`



export const BadgeCheck = styled.div`
  position: relative;  
  display: flex ;
  //background-color: yellow ;

    svg{
      cursor: initial ;
    }

    :hover{

      label{
        visibility: visible;
        opacity: 0.9;
        //top: 30px;
        z-index: 999;
      }
       
    }

    label{
        display: flex;
        position: absolute;
        background: black;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        top: 30px;
        right: 0px;
        left: 30px;
        visibility: hidden;
        transform: translateX(-100%);
        /* display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important; 
        width: max-content;
        padding: 10px;
        transform: translateX(-100%);
        bottom: 0;
        margin-bottom: 30px;
        margin-left: -5px;

        
        font-size: 10px;
        color: white; */
        
        font-size: 10px;
        color: white;
        font-family: ${props => props.theme.font} !important;

        :after{
            content: '';
            position: absolute;
            bottom: 100%;
            right: 7px;
            //left: 50% ;
            border-bottom: 8px solid #000000;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        
        } 
    }
`